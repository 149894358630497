import React, { useState } from "react";
import { BlueOverlay } from "../components";
import { SolutionsTemplate } from "./sub-solutions";
import "../styles/Solutions.scss";

const Solutions = (props) => {
    const [solution] = useState("Pharmaceutical Corporations");
    // const [emailSent, setEmailSent] = useState(false);
    // for when more solutions are required
    console.log(props);
    const displaySolution = (solution) => {
        switch (solution) {
            case "Pharmaceutical Corporations":
                return <SolutionsTemplate data={props.data} />;
            default:
                return <div>Other Solutions</div>;
        }
    };

    // const sendEmail = (email) => {
    //    same as in diabetes
    // };
    return (
        <div className="solutions">
            <BlueOverlay src="header-2.jpg" center flip>
                <div className="hero-header-1-wrapper">
                    <div className="hero-header-1">
                        <div className="selector-wrapper">
                            <div>
                                <h1 className="header-title solutions-title">
                                    {props.data.header.headerSolutionTitle}
                                </h1>
                                {/* <h1 className="header-title word-blue also-in-title">
                                    {props.data.header.headerSolutionTitle}
                                </h1> */}
                            </div>
                            <h5 className="header-description">
                                {props.data.header.headerDescription}
                            </h5>
                            {/* <select onChange={e => setSolution(e.target.value)}>
                        <option value="Diabetes">Diabetes</option>
                        <option value="Nothing">Something Else</option>
                    </select> */}
                        </div>
                    </div>
                </div>
            </BlueOverlay>
            {displaySolution(solution)}
        </div>
    );
};

export default Solutions;
