const MedicalAssociationsData = {
    headerTitle: "Medical Associations",
    headerDescription: `Provide the solution for your physicians to optimize their EMR.`,
    headline1: `Support your physicians to optimize EMR utilization and manage their patient population.`,
    info1: {
        headerText: `Physicians enter patient data into their EMRs in their own ways.
        This often results in a non-standardized patient panel, which impacts the ability to generate an accurate assessment of the patient population.`,
        benefitTitle: `Values an association can expect`,
        benefits: [
            {
                text: "Up to date patient status",
            },
            {
                text: "Accurate rostering",
            },
            {
                text: "Formalized disease registries",
            },
            {
                text: "Sustainable process and workflow in place",
            },
            {
                text:
                    "Software to increase efficiency of quality improvement project",
            },
            {
                text: "Population health improvement",
            },
        ],
    },
    clinicsAd: {
        headerText: `Join these partners using JustPractice to transform
        their business`,
        logoList: [
            {
                logoSrc: "divisions_pic.png",
                name: "Divisions of Family Practice",
            },
            {
                logoSrc: "edocs.png",
                name: "Electronic Medical Record",
            },
            {
                logoSrc: "logo-camrosepcn.png",
                name: "Camrose Primary Care Network",
            },
            {
                logoSrc: "logo-gpsc.png",
                name: "General Practice Services Committee",
            },
        ],
    },
    info2: {
        infoTitle: `Providing an accurate and robust panel
        assessment`,
        subDescriptionTitle1: `Patient status and attachment`,
        subDescriptionText1: `Through customizable parameters that define the status and physician
        attachment of the patients. Panorama can automate the extraction and analysis process to accurately and efficiently
        identify patients that require their status and physician attachment updated.
        This has a direct impact on how many truly active patients a clinic has, as well as who
        is responsible for the care continuity of the patients.`,
        subDescriptionTitle2: `Disease Registry`,
        subDescriptionText2: `As busy practitioners, it is very easy for
        physicians to forget to enter an official
        diagnosis into patient records even after an
        official diagnosis is made. This can
        potentially lead to pseudo-undiagnosed
        patients or wasting time to have to go
        through the process of diagnosing the
        patient again at a later time. A less
        obvious impact is that it is difficult to
        tell right away which patients are living
        with a chronic disease. As a result, it may
        be difficult to identify chronic disease
        patients with clinical care gaps to address.
        Panorama has the capability to
        algorithmically identify patients that
        should have had a diagnosis based on
        customizable parameters, and update the
        disease registry to ensure the most accurate
        panel.`,
    },
    quote: {
        headerText: `"A real time-saver. Helped clarify the
        intricacies of panel management and they did all
        the heavy slogging on our behalf to get things
        in order. Whiz kids!"`,
        quoter: `Dr. Stephen Malthouse - Denman Island Clinics`,
    },
    info3: {
        infoTitle: `Sustainable, customizable, and cost-effective`,
        subDescriptionTitle1: `How do we ensure sustainability?`,
        subDescriptionText1: `We recognize that the motivation for EMR data cleanup and updating
        should come from the physicians themselves to ensure self-sustainability. However, if
        the physicians have never updated the EMR since they have been in practice, the sheer amount
        of data that requires updating may not be achievable through manual work alone. This is why we provide
        our software solution to help automate the initial batch of cleanup so physicians can start
        with a freshly updated panel, as well as help implement a documented workflow in the practice
        to ensure future self-sustainability.`,
        subDescriptionTitle2: `If you can’t make sense of it you can’t
        measure it`,
        subDescriptionText2: `Data can be intimidating. Whether the
        stakeholder is a physician, clinical
        manager, or a key decision maker in an
        organization, no one has time to interpret
        messy, complicated data. Panorama provides
        completely processed data in the most
        intuitive way possible to distill the
        information down to only what one needs to
        know to cut out the noise.`,
    },
};

export default MedicalAssociationsData;
