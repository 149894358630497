import React from "react";
import { withRouter } from "react-router-dom";
import "../../styles/StyledBtn.scss";

const StyledBtn = props => {
    const { action, shadow, className, children } = props;
    return (
        <button
            className={
                shadow
                    ? "styledBtn shadow " + className
                    : "styledBtn " + className
            }
            onClick={action ? () => action() : null}
        >
            <span className="styledTxt">{children}</span>
        </button>
    );
};

export default withRouter(StyledBtn);
