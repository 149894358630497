const CaseStudiesData = [
    {
        id: 1,
        title: "Lack of Clinical Analysis",
        src: "happy-doctor-2.jpg",
        author: "Michael Qiu",
        date: "January 28, 2020",
        category: "Case Study",
        description:
            "As a network of providers who care deeply about providing the best preventative care for their patients, JustPractice has implemented their data analytics software and service on the clinic EMR to identify opportunities for improving patient care. The resources required for this project was generously provided by Boehringer Ingelheim.",
        content: [
            {
                title: "Problem",
                text:
                    "\tMost physicians have moved from pen and paper to digital EMRs. Patient notes are taken through computers and lab results can be accessed and reviewed with a few clicks of the mouse. The increasing digitization of modern medicine has improved patient data access. However, is the data being used to its full potential? \n \n \tIn a busy practice, EMRs are merely used as digital word documents. The physicians will record the consultation for record keeping and future reviews. Most do not have the time nor the expertise to dive into the data. We, at JustPractice, hope to provide the physicians with the tools to gain insight into a data-driven clinical overview.  \n \n \tOur past clients have been mostly family physicians. We have worked with over 100 family doctors across Canada and provided them with a deeper understanding of their patient population using data-driven analytics. They were then able to make evidence based decisions and address care gaps. This time, we had the opportunity to work with two endocrine specialty clinics in Alberta to help analyze their clinical operations and the diabetic population they are managing.",
            },
            {
                title: "Method",
                text:
                    "\tThe project spanned across 2 specialty clinics, with a total of 14 physicians with the following goals: ",
                list: {
                    "1": "Understanding of diabetic population",
                    "2":
                        "How much access to service is provided to the community",
                    "3": "Want to understand patients’ experience",
                },
            },
            {
                text:
                    "\tOn further consultation with the clinical admins across both sites. A “wish-list” was compiled to generate reports addressing the various clinical aspects within the 3 aforementions goals. They are summarized below:",
                list: {
                    "a":
                        "Diabetes Information: patient population, medication lists and lab results",
                    "b":
                        "Access: referral breakdowns, appointment types, followup details",
                    "c": "Quality of Care: lab result correlations and changes",
                    "d": "Innovation: research patients and consults",
                    "e":
                        "Billing Assessment: diagnostic, service and billing assessment",
                },
            },
            {
                text:
                    "\tWe accessed the Accuro EMR system and built reports for data extraction. Only data relevant to the fields above were extracted and analyzed by the PANORAMA system. The data is desensitized with patient identifiable data removed through the process and compiled into a report for clinical analysis.",
            },
            {
                title: "Result",
                text:
                    "\tThrough the use of PANORAMA, JustPractice was able to provide the following list of analytics across both clinics. The list includes additional reports not included in the original wish-list but were generate by PANORAMA to provide deeper insight into the clinics’ operations and patients:",
                list: {
                    "1": {
                        title: "Appointment Data",
                        list: {
                            "a": "Total patients seen in the last 12 months",
                            "b": "Average visits per patient",
                            "c":
                                "Breakdown of appointment types and provider in the last 12 months",
                        },
                    },
                    "2": {
                        title: "Access",
                        list: {
                            "a": "Patient address by quadrant",
                            "b":
                                "Triage levels & correlation between subtypes and levels",
                            "c":
                                "Time between initial referral and first consult by triage level",
                            "d":
                                "Time between initial referral and first consult by consult type",
                            "e": "Provider and consultation breakdown",
                            "f": "Follow up and no show breakdown",
                            "g":
                                "Allied health professionals impact assessment through appointment data",
                        },
                    },
                    "3": {
                        title: "Diabetes",
                        list: {
                            "a": "Diabetic subtypes breakdown",
                            "b":
                                "Diabetic mislabelled patients & missing labels",
                            "c":
                                "Historic A1c levels and changes on a clinic level",
                            "d":
                                "Historic LDL levels and changes on a clinic level",
                            "e": {
                                title: "Diabetic patient medication breakdown",
                                list: {
                                    "i": "Most common diagnosis",
                                    "ii": "Most common medication",
                                },
                            },
                        },
                    },
                    "4": {
                        title: "Other Diseases",
                        list: {
                            "a":
                                "hypertensive patients A1c levels and changes on a clinic level",
                            "b":
                                "hypertensive patients LDL levels and changes on a clinic level",
                            "c": "Osteoporosis patient breakdown",
                            "d": "Thyroid disease patient breakdown",
                        },
                    },
                    "5": {
                        title: "Billing Assessment",
                        list: {
                            "a": "Fee codes breakdown",
                            "b": "Outcomes of billing submissions",
                            "c": "Diagnostic code breakdown",
                            "d": "Service code breakdown",
                        },
                    },
                },
            },
            {
                title: "Conclusion",
                text:
                    "\tThrough the use of PANORAMA, the clinic is able to analyze its patient data to make data-driven decisions on patient management and clinical improvements. For example, the “Access” report outlined the correlation between referral types and wait times. The clinic is able to shift its focus to specific referrals areas to improve patient access. \n \n \tAnother example is the assessment of allied health professionals’ impact on education. We were able to compile the number of educational diabetes appointments by the nurses in relation to the clinic’s diabetes appointment count. This gave the clinic a clearer understanding of the scope of the positive impact the allied health professionals brought, through quantifiable data. \n \n \tIn closing, as physicians move from pen and paper to the new digital age, big data will be a pivotal aspect of modern medicine. Clinics can and should analyze their unique ecosystems to obtain a data-driven understanding of their patient population to practice evidence based medicine. To see how your clinic or practice might benefit from Panorama, please visit our online demo at http://demo.justpractice.ca/.",
            },
        ],
    },
    {
        id: 2,
        title: "State of Patient Data",
        src: "happy-doctor-1.jpg",
        author: "Jorden Lo",
        date: "February 4, 2020",
        category: "Case Study",
        description:
            "For physicians to practice optimally and effectively through the PCN model, the first step required will be to ensure physician EMR data is properly structured and analyzed. This provides a clear picture of the patient population. To achieve this objective, JustPractice has partnered up with organizations such as the Divisions of Family Practice and General Practice Services Committee (GPSC) to work with physicians and their data.",
        content: [
            {
                title: "Problem",
                text:
                    "\t4000 active patients? Half of them haven’t been seen in the last 5 years? Only 12 diabetic patients according to your EMR, when diabetes is rising exponentially in prevalence? Sounds suspicious doesn’t it? As a physician, you might have experienced these and think to yourself: “This is not possible. There is something missing with the current state of my EMR data.” That’s half the picture. Data isn’t necessarily missing in your EMR, rather, it is simply not structured properly. This leads to an inaccurately high active patient count, as well as an inaccurate disease registry composition. Without knowing how many active patients you truly have, it is difficult to allocate your time as a physician or staff time to see patients, as it is difficult to gauge how much time your patient population will demand from you. Furthermore, without an accurate disease registry, one can’t know the total number of patients in each chronic disease category. This makes it difficult to help manage chronic disease patients as resources cannot be efficiently allocated without knowing the number of patients (i.e. difficult to determine how many diabetes educator nurses to hire without knowing how many active diabetic patients are there). It will also be difficult to implement quality improvement initiatives as it will be hard to identify which patients have specific chronic disease care gaps.",
            },
            {
                title: "Means attempted to solve problem",
                text:
                    "\tPhysicians went through medical school to learn how to diagnose and treat their patients, not how to manage their patient data let alone deriving data insights to boost their practice. Furthermore, there is enough administrative tasks associated with each patient already such as writing up soap notes. Patient data management and analysis is the last thing on their minds as they go home at 5 O’clock. This leads to a vicious cycle of mismanaged data piling up, leaving physicians overwhelmed and not knowing where to start if they want to make data driven decisions and derive insights from their valuable data. Some have tried to tackle this issue by hiring manual labor to update the EMR data. The result was slow, expensive, inefficient, and inaccurate, as with most manual labor in comparison to software automation.",
            },
            {
                title: "Newly discovered solution",
                text:
                    "\tWith thousands of patients per physician and tens of thousands of data points (labs, medications, investigations, etc), it is impossible and impractical to manually go through the entire EMR database. This is when JustPractice comes in with Panorama. With our proprietary software, we are able to automate the process of analyzing the entire EMR database for data restructuring and making data driven decisions that will benefit the practice.",
            },
            {
                title: "The result",
                text:
                    "\tJustPractice has partnered with provincial level healthcare agencies to improve physicians’ practices through:",
                list: {
                    "1":
                        "Structuring and standardizing EMR data to enhance data quality",
                    "2":
                        "Run data analytics for quality improvement and patient outcome improvement purposes",
                },
            },
            {
                text:
                    "\tWe will explore in detail the qualitative process JustPractice took to execute the project. To determine whether patients were active or inactive, our software conducted data analysis looking at patient visits or appointment history. If a patient has had a visit within a period of time set by the physician, the patient was considered active. If not, the patient was considered inactive. Our software then automated the process of changing the status of each patient to either active or inactive. This process resulted in clinics deactivating patients who were not in for the last 5 years or so, and who are not likely to come in for a visit for another 10 years. These patients being labelled active causes an illusion to the clinics that they are “overwhelmed” by too many patients, when in fact they have the capacity to take on more patients to utilize their resources such as staffing more efficiently. Some clinics experienced hundreds to thousands of patients being inactivated, which resulted in them taking in more patients. This is critical not only for a clinic’s operations, but also crucial when thousands of patients are unable to find family physicians who are accepting patients.",
            },
            {
                text:
                    "\tOn top of analyzing EMR data for more accurate patient status, we were also able to provide a more accurate disease registry. Our software sifted through all patient data in order to identify patients that should be diagnosed with a chronic disease based on physician’s preference but were not done so within the EMR. Our software looked at all relevant fields such as billing history, screening exams, lab results, free text written diagnosis, etc. These fields will allow our software to determine whether a patient should have had an official diagnosis labelled, but was not done so by the physicians. Our software then automates the process of applying the appropriate ICD9 code correctly to ensure they have the correct disease labelled.On top of analyzing EMR data for more accurate patient status, we were also able to provide a more accurate disease registry. Our software sifted through all patient data in order to identify patients that should be diagnosed with a chronic disease based on physician’s preference but were not done so within the EMR. Our software looked at all relevant fields such as billing history, screening exams, lab results, free text written diagnosis, etc. These fields will allow our software to determine whether a patient should have had an official diagnosis labelled, but was not done so by the physicians. Our software then automates the process of applying the appropriate ICD9 code correctly to ensure they have the correct disease labelled.",
            },
            {
                text:
                    "\tHaving an updated and accurate disease registry panel of patients enables opportunities to improve patient care. When all patients are correctly diagnosed and labelled correctly, analytics conducted on EMR data will be able to more robustly identify chronic disease patients as well as their associated care gaps according to guidelines. When chronic disease patients with care gaps are identified, they can then be recalled and addressed to improve long term outcomes. First step in improving any chronic disease population is identifying the right patients.",
            },
            {
                text:
                    "\tJustPractice’s Panorama software not only has the analytical capability to identify patient data that requires updating and restructuring, it also has the capability to identify patient care gaps with parameters reverse engineered from guidelines, as well as operational inefficiencies in clinics. With a comprehensive understanding of a clinic’s operations, EMR data state, and patient states, managers can now make data driven decisions to improve their practice overall.",
            },
            {
                text:
                    "\tIn the current provincial push for PCN, accurate patient status and accurate disease registries are the first step towards proper resource allocation to the clinics that require the most support. The goal is to set up a community centred around the patients. PCN models are shown to reduce primary physicians’ workload by allocating allied health professionals based on data. Without accurate data, the province may not know how to help your community the right way.",
            },
            {
                text:
                    "\t Here is a link to our demo account to showcase what the doctors will see from our analysis: https://demo.justpractice.ca",
            },
        ],
    },
    {
        id: 3,
        title: "Why should you care about your EMR data?",
        src: "happy-doctor-3.jpg",
        author: "Danny Lo",
        date: "February 2, 2020",
        category: "In the Know",
        description:
            "This may come as a surprise, but your EMR is more than just a glorified scheduling and billing system. It is a wealth of information about your patients and your practice. It is the most informative source of data about your entire practice, where you can identify opportunities to improve both your care for your patients, and clinical operations such as billing.",
        content: [
            {
                title: "Let's define EMR data first",
                text:
                    "\tPractically every physician’s office uses Electronic Medical Records (EMR) to document everything about their patients. EMR data documented include medications, allergies, diagnosis, billing history, etc. The EMR data is a wealth of information to the physician as it provides a detailed overview of their patient population that will allow physicians to improve patient care and clinic operations (a win-win situation for all parties involved). Unfortunately, this data is often not structured properly, let alone analyzed to improve care.",
            },
            {
                title: "How can my EMR data affect my practice?",
                text:
                    "\tLet’s say you have a practice with 1200 patients. Out of all the patients, most of them will likely have multiple comorbidities (such as diabetes, COPD, hypertension, etc) that need to be managed or monitored closely. Take your diabetic patients for example, according to Diabetes Canada guidelines on dyslipidemia, they should all have their LDL levels below 2.0 mmol / L to minimize CVD risks. Patients who are above this target should be considered for statin therapy. From a physician’s EMR, one can tell based on recorded lab data and diagnosis which patients are diabetic and have their LDL levels out of target. These patients can then be recalled by the clinic to have the physician address their care gaps.",
            },
            {
                title:
                    "How feasible is this considering I have hundreds if not thousands of patients?",
                text:
                    "\tMost physicians have an enormous number of patients to take care of. With a large patient size, multiple comorbidities among each patient, and a countless number of potential care gaps from each comorbidity, this is clearly not feasible if the identification of these patients is done manually and individually from each patient profile. Fortunately, most EMR systems have a report function built in for users to query a specific set of patient population they would like to look for (eg Diabetic patients with LDL > 2mmol/L not on statin therapy). Once these patients are identified, the practice will know who to recall to address the care gap in question. A query with a click of a button to identify a patient population beats having to manually go through thousands of patient profile to see if the patient fits the care gap parameter.",
            },
            {
                title: "Does the state and quality of the EMR matter?",
                text:
                    "\tMost definitely! Even with the best analytics software, garbage in = garbage out. If the data is not structured properly, or worse if the data doesn’t even exist, it can’t be analyzed properly. This is why every EMR system should be structured optimally to ensure the quality of the data is at its best prior to analysis. For instance, if a patient has officially been diagnosed with diabetes, but it isn’t recorded in the diagnosis field, when a query is looking for diabetic patients, these patients will not show up in the reports. As a result, patients that may need to be recalled to address a care gap may be missed. This is why it is important to ensure patients are labelled properly in the EMR. This is just one of many examples of how patient data may be improperly structured to affect data analysis.",
            },
            {
                title: "Still sounds too complicated or too much work?",
                text:
                    "\tWe understand that all this might sound like a lot of work, and will require a sophisticated level of technological know-how, but it is an extremely effective opportunity to improve your care for your entire patient population. We understand that as physicians, you are extremely busy with seeing patients already, let alone having to implement data analytics on your EMR system to improve patient care. This is why JustPractice has built a cloud based software for automating both data structuring and analysis to ensure accuracy and efficiency. We hope to be able to assist physicians in improving patient care, while minimizing the tedious tasks required to do so.",
            },
            {
                text:
                    "\tTo see how our software helps physicians be better healthcare providers, visit https://demo.justpractice.ca/signin.",
            },
        ],
    },
    {
        id: 4,
        title:
            "Healthcare is only getting more expensive. What’s the antidote?",
        src: "healthcare-costs.png",
        author: "Jorden Lo",
        date: "June 8, 2020",
        category: "In the Know",
        description:
            "Healthcare spending and expenditure is rising at an exponential pace. This is due to a variety of factors compounding with one another. These factors include, but are not limited to population growth, the advancement of population age, and the increase in chronic disease prevalence. This makes chronic disease management and preventative care more important than ever. Read on to learn how big data can be used to augment preventative medicine for a sustainable future!",
        content: [
            {
                title: "A provincial-wide problem seen everywhere in the world",
                text:
                    "\tThe population is getting older, the prevalence of chronic diseases is skyrocketing, and physicians are retiring at a rapid pace. When you add these three factors together, you get an alarming increase in healthcare spending, diminished healthcare access, and poorly managed care that leads to worse outcomes. Patients with chronic diseases and healthy patients will require preventative care more than ever. Prevention may be more expensive in the short term, but it pays dividends and dramatically reduces healthcare costs in the long run, which is much needed at the current rate of increase in healthcare expenditures in BC, as well as the rest of the world.",
            },
            {
                title: "What does preventative medicine mean?",
                text:
                    "Traditionally, medicine is practiced in a way that addresses an issue a patient is presenting with. For patients with chronic diseases like diabetes, these issues are often indicators and clues that a patient is already starting to deteriorate. This method of practice emphasizes on moving patients from being unhealthy to well again, whereas preventative medicine has its emphasis on keeping the patients healthy from the beginning. This approach improves the overall quality of life for patients, provides better outcomes, and reduces long term costs and spending. An amputation surgery to remove a diabetic foot is more expensive than regularly checking and controlling a patient’s HbA1c. Regular cholesterol checks and statins are significantly cheaper than the costs associated with hospitalization from a myocardial infarction. The list goes on.",
            },
            {
                title:
                    "A powerful antidote to combat the rise in healthcare costs",
                text:
                    "Preventative medicine lies at the heart of curbing the increase in healthcare spending, and preventative medicine begins with a robust data-driven approach. In order for physicians to practice preventative medicine and address clinical care gaps for patients, they need to know which patient of theirs is currently living with care gaps, as well as strategies to address these gaps. This relies on robust data analytics on patient medical data to efficiently and quickly identify patients with care gaps as their own medical data suggests. Furthermore, millions of patients live with multiple care gaps concurrently, so it must be a solution that scales.",
            },
            {
                title: "How to get started?",
                text:
                    "A robust data analytics pipeline begins with a high-quality data source. Most often, a physician’s EMR system will contain the most comprehensive data on each patient that can provide critical and useful information on which patients should be recalled due to care gaps. However, physicians aren’t usually trained to properly input patient data in a standardized or optimized format. This makes an accurate data ETL (extract, transform, load) difficult, which results in poor data analytics on which patients need the extra preventative care to address care gaps. Therefore, the first step should be to clean up and standardize the patient data in a sustainable fashion. Only when the data is standardized and structured appropriately can proper analysis be done. Furthermore, it has to be done in a sustainable fashion that the clinics themselves can maintain the integrity of the data, so quality improvement initiatives to address patient care gaps can be conducted indefinitely.",
            },
            {
                title: "Concluding remarks",
                text:
                    "As EMRs have been implemented for decades, and physicians have been entering patient data into the EMR in their own, non-standardized method, there are incredible challenges in being able to implement a data clean up initiative that structures the data to optimal quality, while maintaining that standard indefinitely which is crucial in being able to conduct future quality improvement initiatives in practicing preventative medicine and improve patient outcomes. Because the challenges for data cleanup and restructuring are complex and the solution even more so, we will be addressing these in another educational article.",
            },
        ],
    },
    {
        id: 5,
        title: "Data Analytics with EMRs",
        src: "data-analytics.jpg",
        author: "Jorden Lo",
        date: "July 1, 2020",
        category: "In the Know",
        description:
            "The EMR systems serve as a wealth of information that can be leveraged to increase quality of patient care, improve clinical operations, and even billing. Analytics need to be properly conducted in order to realize the potential of EMR data. This can be done in different ways, and the right approach should be determined based on where the clinic stands.",
        content: [
            {
                title: "EMR built-in reporting function",
                text:
                    "Most, if not all EMR systems have a built-in reporting function. This reporting function can be very powerful to conduct many different types of analytics on patient data. These analytics could also be highly impactful for physicians that want to increase their billing, identify clinical care gaps, and even identify opportunities to improve clinical operations.",
            },
            {
                title:
                    "If most EMRs already have a built-in reporting function, why would anyone need to run analytics off of the EMR?",
                text:
                    "The built-in function for analyzing EMR data is itself very useful and is often enough to serve the needs of many clinics. However, in order to leverage this reporting function, a few criteria need to be satisfied:",
                list: {
                    "1":
                        "Providers that are utilizing the EMR must have strict standards for how they have been entering data. The data not only has to be entered correctly but all the providers that share the same EMR system should be entering data in the same, standardized way",
                    "2":
                        "The data needs to be cleaned and formatted in an optimal structure. It’s unreasonable to expect all physicians to not only enter data into the EMR the same way but also expect them to enter data correctly. As busy practitioners, they may not have time for this. The resulting data is often not optimized for analysis and needs to be cleaned up first prior to analysis. If the data remains messy, the analysis will yield inaccurate results",
                    "3":
                        "The analyst conducting the EMR analysis needs to be trained to become familiar with the EMR system itself, learn how to operate the reporting function, and also know how to issue the right queries to generate the data that provides insights. This is quite resource-intensive",
                },
            },
            {
                text:
                    "The built-in EMR data reporting function is very powerful and useful for clinics and practitioners that are looking to conduct their own analytics, but the aforementioned criteria should be satisfied to set the project up for success.",
            },
            {
                title: "Sounds like a lot of work...are there any shortcuts?",
                text:
                    "There is absolutely a better way to do this! Most of the work involved in both setting the analytics up and the analytics itself can be automated with the help of software automation. For organizations and clinics that have the resources and the motivation to develop their own technical solutions, we would recommend conducting the analytics off of the EMR platform, and from their own in-house data warehouse instead. By having the EMR data in their own data warehouse, organizations can now clean up, structure, and automate much of the analysis without much technical friction. All of these automation steps are incredibly difficult to achieve within the EMR environment itself due to the closed-source nature of most of the EMR on the market.",
            },
            {
                title: "Things to consider",
                text:
                    "As organizations move away from EMR systems and towards conducting analytics from their own data warehouse, they no longer have to worry about:",
                list: {
                    "a":
                        "The data being too messy, which results in inaccurate analysis",
                    "b":
                        "The data not being structured optimally, which also results in inaccurate analysis",
                    "c":
                        "Having to hire and train “EMR specialists” that manually run queries within EMR environments, which can become expensive",
                },
            },
            {
                text:
                    "When organizations take on this new direction, two key factors should be considered for the data ETL pipeline:",
                list: {
                    "1":
                        "Data extraction from EMR systems should be automated and optimized to ensure a robust extraction that provides data that is consistent and also financially sustainable",
                    "2":
                        "Data cleansing should also be conducted every time data is pulled from an EMR in a standardized method to ensure the data not only fits the data warehouse but also to ensure the analysis will be accurate",
                },
            },
            {
                text:
                    "These two key factors will directly impact success for the data ETL pipeline.",
            },
        ],
    },
    {
        id: 6,
        title: "Data Cleaning: What does it mean and why it matters",
        src: "data-cleaning.jpg",
        author: "Jorden Lo",
        date: "July 1, 2020",
        category: "In the Know",
        description:
            "Before analytics on EMR data can be conducted, the data must be cleaned up and structured appropriately to ensure the most accurate results can be produced. Without an appropriately structured data, the results of the analysis will be inaccurate, making data-driven decisions difficult to achieve.",
        content: [
            {
                title: "What does it mean?",
                text:
                    "Data cleaning isn’t one of those abstract data science terms with no specific meaning. Data cleaning is the process of detecting and correcting datasets that are either corrupt, inaccurate, incomplete or not structured appropriately. Simply put, when data is required for measurement and quality improvement purposes, it not only needs to be complete, but it also needs to be structured properly. The process for ensuring that is known as data cleaning.",
            },
            {
                title: "How is it relevant to EMR data?",
                text:
                    "Electronic medical records (EMR) are systems that are used in clinics for physicians and other healthcare providers to enter and store information about specific patients. These systems are responsible for digitizing patient charts. The specific clinical utilities and benefits of a well-deployed EMR system are beyond the scope of this article, but what is relevant is that because data and information about patients can be entered into the EMR in drastic different ways by different people, the data in the EMR systems are stored in a non-standardized state. Furthermore, due to the busy nature of a clinic, information is often entered either incorrectly, or correctly but not in the right data field. This results in important information and datasets about patients being difficult to access, extract, and analyze, which are crucial for both measurement and quality improvement purposes from the data aspect.",
            },
            {
                title: "Why is this an issue?",
                text:
                    "When the data remains messy and unstructured, analysis can be very difficult. Even if analysis can be conducted on messy data, the results are often inaccurate and can paint the wrong picture for the patient population as well as the current quality of care. Without robust analysis, it can:",
                list: {
                    "a":
                        "Be difficult for a physician supporting organization to objectively track/measure how the physicians are doing in terms of QI",
                    "b":
                        "Be difficult for both physician supporting organizations and physicians themselves to see what kind of strategies and resources will be most effective in improving the quality",
                },
            },
            {
                title: "What are some possible solutions?",
                text:
                    "Solutions can be deployed to clean up and structure the data at any point in the data ETL (extract, transform, load) pipeline. In fact, the problem can be solved even before the ETL pipeline, which is to clean up the EMR data right within the EMR system itself. There are different tradeoffs to each of the points where the data cleansing is done, and the right solution should be selected depending on needs. For instance, data cleansing right within the EMR system may be more expensive, but it reduces friction downstream when pulling the data for analysis. However, physicians and EMR managers need to be trained and motivated enough to maintain the integrity of data entry to sustain the cleanliness of the EMR data. The tradeoffs should be considered when choosing the appropriate solution. The operations and deployment of these solutions will all differ from one another and should be selected based on the willingness to change, the mentality of the stakeholders, as well as the objectives of the initiatives. In any scenario, the importance is having the end product of EMR datasets to be cleaned, transformed, and structured optimally and appropriately.",
            },
            {
                title: "Concluding Remarks",
                text:
                    "In order to truly leverage the power of utilizing EMR data for both measurement and quality improvement initiatives, one of the main prerequisites is to have clean EMR data. The truth is simple: garbage in equals garbage out. Proper analytics on the EMR data cannot be conducted unless the data is clean, and there are multiple approaches to providing that. The approach and the right solution should be designed based on the needs of the organization, as well as the objectives that one is trying to achieve.",
            },
        ],
    },
    {
        id: 7,
        title: "EMR data cleansing on a provincial level",
        src: "data-clean.jpg",
        author: "Jorden Lo",
        date: "July 24, 2020",
        category: "In the Know",
        description:
            "Different provinces in Canada are starting to work towards a model of having clean and well-structured EMR data in primary care settings. This is important for both physician-supporting organizations as well as the individual practices themselves, and it is essential for providing proactive care for the patients.",
        content: [
            {
                title: "What does messy data mean?",
                text:
                    "EMR data can be “messy” and disorganized in many different ways. It could mean that the physician has been entering encounter notes into appointment notes, or it could mean that the diagnosis made by the physician was not entered into the problem list. All of the disorganizations can lead to inefficiencies and inaccuracies when it comes to utilizing the EMR data for improving clinical operations and quality of patient care. No EMR system can ever be 100% well-structured in every aspect, but there are a few fundamental aspects of the EMR data that should remain as clean as possible for as frequently as possible. These aspects can provide important insights into the clinic’s overall operation.",
            },
            {
                title: "What are these aspects?",
                text:
                    "Organizations that are interested in cleaning up primary care physicians’ EMR data on a large scale commonly focus on the following aspects:",
                list: {
                    "1": "Patient Status",
                    "2": "Patient Attachment",
                    "3": "Disease Registry",
                },
            },
            {
                title: "Why do these aspects matter?",
                subtitle: "Patient Status",
                text:
                    "This refers to the status of the patient as indicated within the EMR. The status can be active, inactive, deceased, or moved. There are other statuses that can be used, but these four are the most common. Patient statuses should be frequently updated and cleaned up as it has a direct impact on patient panel size. If the patient status field isn’t frequently updated, the patient panel size (number of active patients, number of inactive patients, etc) as determined from the EMR would not be accurate. This further affects other QI initiatives that require more in-depth analytics of the EMR data. Resource allocation based on panel size will also not be optimized.",
            },
            {
                subtitle: "Patient Attachment",
                text:
                    "In the context of primary care, it is crucial for patients attach themselves to a family physician. This has obvious benefits such as continuity of care. Even when patients are indeed attached to a specific physician, this may not be reflected within the EMR under the most responsible physician field. Patients that are not properly attached to their physicians as indicated within the EMR will affect true panel size calculations, as well as affect the determination of which physician is most responsible for the care and coordination of the patient. The lack of care coordination can have an impact on the patient’s health as any clinical care gaps could easily fall through the cracks without an accountable physician.",
            },
            {
                subtitle: "Disease Registry",
                text:
                    "Often times when a primary care physician makes an official diagnosis for a patient based on guideline parameters, they might forget to enter the proper ICD-9 code and associated diagnosis documentation into the EMR. This lack of disease coding can cause an underestimation of disease prevalence within a practice when analyzing specific disease areas through the EMR. The underestimation and lack of proper ICD-9 coding can make patients with clinical care gaps difficult to identify for future QI activities. This can have an obvious impact on the quality of care being provided for these patients affected by chronic diseases. Furthermore, disease-specific resources such as a diabetes educator can be difficult to optimally allocate without accurate disease registries.",
            },
            {
                title: "Clean up on a practical level",
                text:
                    "Every practice can and should determine for themselves how to appropriately identify patients with data that should be cleaned up and structured better. That said, clinics should determine those patients in the context of the three aspects: patient status, attachment, and disease registry as those three aspects are important fundamentals. Within these three areas, physicians can determine the parameters that will help them identify those patients. For example, they should determine what constitutes an active patient that is appropriate for their practice, or how they determine when a disease diagnosis can be made and labelled with the correct ICD-9 code. Although these parameters should be customized to fit the practice, we encourage practices to set these parameters within the context of the three areas mentioned above as a starting point. We will mention some of the parameters we commonly use with clinics in another article, as well as a recommended approach on how to execute the cleanup.",
            },
        ],
    },
    {
        id: 8,
        title: "Parameters that matter for provincial EMR data clean up",
        src: "emr-cleanup.jpg",
        author: "Jorden Lo",
        date: "July 31, 2020",
        category: "In the Know",
        description:
            "There are many approaches to how a physician can clean up their EMR data. Furthermore, parameters for clean up should be chosen carefully that is specific and tailored to the practice’s needs. We explore here some parameters that are commonly selected by physicians.",
        content: [
            {
                title: "Priorities",
                text:
                    "In our previous article, we briefly touched on the three main areas of EMR data clean up that provincial-level organizations and clinicians should focus on first. These three areas are:",
                list: {
                    "1": "Patient Status",
                    "2": "Patient Attachment",
                    "3": "Disease Registry (Also known as diagnosis registry)",
                },
            },
            {
                linkedtext: {
                    text:
                        "The reason why these three areas should be prioritized is beyond the scope of this article, and it can be explored at $1",
                    links: [
                        {
                            href: "https://justpractice.ca/case_studies/7",
                            text: "here.",
                        },
                    ],
                },
            },
            {
                text:
                    "Within the scope of this article, we will be diving into the practicality of what kind of parameters should be looked at in each of the three categories and how those parameters can be used for clean up.",
            },
            {
                title: "Patient Status",
                text:
                    "At any given time, not all patients that are labelled as active within the EMR will be truly active. These patients may actually be inactive, deceased, or moved. It is important to identify who these patients are, and inactivate them. Without this process, the true number of patients that are active within the practice cannot be determined with accuracy, which could result in a single family physician having 6000 active patients rostered under them (which is impossible).",
            },
            {
                text:
                    "Parameters that are useful for determining who these patients are should be tailored specifically to the type of practice, but generally speaking, clinics often use the following parameters:",
                list: {
                    "a":
                        "Patients labelled as active with no visit in the last 3-5 years",
                    "b":
                        "Patients labelled as active with no appointment booked in the last 3-5 years",
                    "c":
                        "Patients labelled as active with no billing items in the last 3-5 years",
                },
            },
            {
                text:
                    "Depending on the normal patient turnover frequency of the clinic, the time span should be shortened or extended (high patient turnover would indicate a shorter time and vice versa).",
            },
            {
                title: "Patient Attachment",
                text:
                    "One of the biggest impacts and roles a family physician can play is to provide continuity of care for their patients. The days of patients seeing whichever family physician is available and only when they are sick are over. The first step for allowing family physicians to provide continuity of care is to ensure the patients are attached to the right family physician. If the patients aren’t attached to the right family physician, it is difficult for any provider to take responsibility for the patient’s health. Oftentimes, there could be patients that are rostered to a specific clinic but not rostered to any specific physician. This can create confusion as to who is most responsible for the patient. An appropriate set of parameters can be used to determine which physician these patients should be rostered under. These parameters are (but not limited to):",
                list: {
                    "a":
                        "Patients having had visits with one provider only - this provider should be assigned the patient",
                    "b":
                        "Patients having had visits with one provider more frequently than the rest of the providers - this provider should be assigned the patient",
                    "c":
                        "Patients having seen multiple providers equally - the provider the patient last saw should be assigned the patient",
                },
            },
            {
                text:
                    "Similar to patient status, the workflow and process for determining the patients’ most responsible physician should be tailored to the practice. For example, physicians that are newer to practice with fewer patients rostered can afford to take on more patients when compared to another physician who has been practicing for longer. The parameters are not hard and fast rules for rostering patients.",
            },
            {
                title: "Disease Registry",
                text:
                    "Disease registry clean up is the process for assigning the correct ICD-9 code to patients that should or have received a diagnosis but not properly labelled within the EMR. This aspect is arguably the most physician and clinic specific aspect for EMR data clean up. The reason for that is because ICD-9 diagnoses of diseases depend on how closely physicians adhere to clinical practice guidelines, as well as which guidelines they follow. Furthermore, there are many chronic diseases that practices can choose to prioritize for clean up. Some of the most commonly chosen diseases are:",
                list: {
                    "1": "Diabetes Mellitus",
                    "2": "Heart Failure",
                    "3": "Hypertension",
                    "4": "Chronic Kidney Disease",
                    "5": "Depression/Anxiety",
                },
            },
            {
                text:
                    "Depending on how in-depth and comprehensive the practice wants to clean up their disease registry, the parameters can include billing history, mention of diagnosis, medications, lab results, etc. Taking diabetes as an example registry, some of the commonly used parameters are:",
                list: {
                    "a":
                        "Diabetes mellitus (250) billing item present in the patient chart",
                    "b":
                        "Mention of diabetes mellitus diagnosis in physician documented visit notes",
                    "c": {
                        text: "A1c ≥ 6.5% twice in laboratory results $1",
                        links: [
                            {
                                href:
                                    "https://www.diabetes.ca/health-care-providers/clinical-practice-guidelines/chapter-3#panel-tab_FullText",
                                text: "(Diabetes Canada Guidelines)",
                            },
                        ],
                    },
                },
            },
            {
                text:
                    "The parameters should be tailored to what kind of and how closely physicians adhere to guideline recommendations as well as the disease in question.",
            },
            {
                title: "Closing",
                text:
                    "Many of the parameters we have recommended here are merely parameters that are commonly used and agreed upon among physicians; however, the parameters chosen should be carefully considered to ensure they are uniquely tailored to the practice.",
            },
        ],
    },
    {
        id: 9,
        title: "Modern Solutions for Modern Practices",
        src: "modern-solutions.jpg",
        author: "Michael Qiu",
        date: "August 30, 2020",
        category: "In the Know",
        description:
            "As the older style of reactive medicine transitions into proactive medicine, physicians are starting to take on more administrative tasks that are important, but difficult to balance with their everyday medical duty. Read on to find out how we leverage our software to reduce workload for physicians as they optimize their EMR for preventative medicine, while achieving a 96% satisfaction rate among them with the GPSC initiative.",
        content: [
            {
                title: "A shift in Practice",
                text:
                    "Family physicians are no longer just seeing patients through the door for same day appointments. Since the new millennium, GPs have been taking a holistic approach to patient health and integrating EMR (electronic medical records) to improve all aspects of patient care.",
            },
            {
                text:
                    "However, the technology behind it all often isn’t intuitive. With so many options available, physicians have to learn all the functions and capabilities helpful totheir practice. More importantly, it can be a very time-consuming endeavor.",
            },
            {
                text:
                    "The 2010 National Physician Survey (Figure 1.) reported that the average family physician worked 49.8 hours per week compared to the 36.2 for the average Canadian. Of the 49.8, 32.9 hours are spent on direct patient care while the remaining 16.6 hours are spent on indirect patient care (reports, phone calls) and others (admin). In comparison, the 2004 survey showed that family physicians spent 34.5 of the 49 hours weekly on direct patient care, 1.6 hours more per weekcompared to the 2010 report.",
            },
            {
                text:
                    "Interestingly, the same 2010 survey reported work hour discrepancies based on record keeping systems (Figure 2). On average, family physicians on EMRs worked 3.7 hours more than paper only physicians per week. EMR-based physiciansalso spent more time on the admin and in-direct patient care aspect of the practice. More physicians felt they are working for the EMRs instead of the other way around.",
                image: [
                    { src: "fig1.png", fig: "Figure 1.", type: "multi" },
                    { src: "fig2.png", fig: "Figure 2.", type: "multi" },
                ],
            },
            {
                title: "The Remote Solution",
                text:
                    "Based on the aforementioned data, it is easy to understand why physicians are reluctant to initiate quality improvement projects that might add more administrative hours to their busy schedule. Despite the multitude of evidence behindproactive care and EMR optimization, the average family physician is just too busy.",
            },
            {
                text: "This is where JustPractice comes in.",
            },
            {
                text:
                    "Through the GPSC (General Practice Services Committee) in BC, we have been tasked to help physicians with their panel management project. The key goals are to keep an updated patient panel and make sure the disease registries are up to date. The entire process was originally time consuming and required physicians to set aside hours every week to complete the tasks.",
            },
            {
                text: `However, JustPractice has deployed a remote panel management model with integrated software to reduce the physician burden every step of the way. We are able to cater to the physician’s schedule and clinical needs and leverage our technology to minimize the amount of admin time needed from the physicians. We are able to take the physician feedback to customize and automate the process of 
                removing inactive patients, updating disease registries, finding eligible patients for recalls and much more!`,
            },
            {
                text:
                    "We are proud to share that we have served more than 200 physicians in BC across 70+ clinics with a 96% satisfaction rate (Figure 3.) Several physicians have said that they would not consider patient panel optimizations at all if it weren’t for our efficient services.",
            },
            {
                text:
                    "Are you interested to see how we can help improve your practice or patient care withlittle to no administrative burden? Check us out at https://demo.justpractice.ca/or drop us an email. We are happy to help!",
                image: [{ src: "fig3-2.jpg", fig: "Figure 3.", type: "flex" }],
            },
        ],
    },
    // {
    //     id: 5,
    //     title: "C-endo Qualitative Case Study",
    //     src: "",
    //     author: "Jorden Lo",
    //     date: "March 9, 2020",
    //     category: "Case Study",
    //     description: "",
    //     content: [
    //         {
    //             title: "Why was the partnership formed?",
    //             text:
    //                 "\tC-Health is a multi-divisional practice that is composed of C-Era for cardiac health, C-Endo for endocrine health, and C-O2 for cardiopulmonary health. They have a strong focus on providing the best care for their patients, as well as being the most patient-centred care they can be. As a practice aspiring to provide the best care possible, they have enlisted JustPractice’s expertise and experience in utilizing Panorama to help identify areas within their C-Endo clinics to see how they can improve their care for their patients, streamline clinical operations, and allocate resources efficiently within their clinics, all with the help of Panorama’s capability to conduct deep analysis on their patient/clinic data."
    //         },
    //         {
    //             text:
    //                 "\tIn this case study, we present some of the analytics we were able to perform for C-Endo’s clinics and how it benefited them. All data presented are anonymized for privacy reasons."
    //         },
    //         {
    //             title: "Insights provided by Panorama",
    //             list: {
    //                 "1": {
    //                     title: "Clinical Operations:",
    //                     list: {
    //                         "1": {
    //                             title: "Appointment data",
    //                             text:
    //                                 "For C-Endo, Panorama was able to analyze the percentage of appointments based on providers.",
    //                             table: {
    //                                 headers: [
    //                                     "Doctors",
    //                                     "Percentage of the total appointment"
    //                                 ],
    //                                 body: [
    //                                     ["Doctor 1", "2.48%"],
    //                                     ["Doctor 2", "3.80%"],
    //                                     ["Doctor 3", "1.16%"],
    //                                     ["Doctor 4", "1.45%"],
    //                                     ["Doctor 5", "4.71%"],
    //                                     ["Doctor 6", "0.08%"],
    //                                     ["Doctor 7", "14.59%"],
    //                                     ["Doctor 8", "25.99%"],
    //                                     ["Doctor 9", "0.15%"],
    //                                     ["Doctor 10", "0.12%"],
    //                                     ["Doctor 11", "10.78%"],
    //                                     ["Doctor 12", "0.46%"],
    //                                     ["Doctor 13", "8.14%"],
    //                                     ["Doctor 14", "3.62%"],
    //                                     ["Doctor 15", "1.36%"],
    //                                     ["Doctor 16", "2.50%"],
    //                                     ["Doctor 17", "8.11%"],
    //                                     ["Doctor 18", "10.51%"],
    //                                     ["Grand Total", "100.00%"]
    //                                 ]
    //                             }
    //                         },
    //                         "2": {
    //                             text:
    //                                 "This information was useful for the clinic to see the objective workload of each physician for resource allocation purposes. For example, the clinic can now see that Doctor 8 has the greatest number of appointments by percentage, and should be allocated more resources accordingly."
    //                         },
    //                         "3": {
    //                             text:
    //                                 "Appointment types were also analyzed and categorized by physicians. In C-Endo, we were able to correlate the types and number of consults to each physician.",
    //                             table: {
    //                                 headers: [
    //                                     "Consult type",
    //                                     "Dr. A",
    //                                     "Dr. B",
    //                                     "Dr. C",
    //                                     "Dr. D",
    //                                     "Dr. E",
    //                                     "Grand Total"
    //                                 ],
    //                                 body: [
    //                                     [
    //                                         "Bariatric Consult",
    //                                         "117",
    //                                         "1",
    //                                         "115",
    //                                         "0",
    //                                         "2",
    //                                         "235"
    //                                     ],
    //                                     [
    //                                         "Diabetes Consult",
    //                                         "327",
    //                                         "377",
    //                                         "182",
    //                                         "5",
    //                                         "22",
    //                                         "913"
    //                                     ],
    //                                     [
    //                                         "Diabetes Education Consults",
    //                                         "2",
    //                                         "1",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "3"
    //                                     ],
    //                                     [
    //                                         "Endo Consult",
    //                                         "275",
    //                                         "337",
    //                                         "161",
    //                                         "2",
    //                                         "128",
    //                                         "903"
    //                                     ],
    //                                     [
    //                                         "Lipid Consult",
    //                                         "45",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "45"
    //                                     ],
    //                                     [
    //                                         "Phone Consult",
    //                                         "22",
    //                                         "80",
    //                                         "54",
    //                                         "33",
    //                                         "12",
    //                                         "201"
    //                                     ],
    //                                     [
    //                                         "Thyroid Consult",
    //                                         "212",
    //                                         "205",
    //                                         "141",
    //                                         "0",
    //                                         "48",
    //                                         "606"
    //                                     ],
    //                                     [
    //                                         "Urgent Endo Consult",
    //                                         "0",
    //                                         "3",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "3"
    //                                     ]
    //                                 ]
    //                             }
    //                         },
    //                         "4": {
    //                             text:
    //                                 "This provides the clinic with an understanding of which physician is involved most heavily with which types of consults. This insight allows clinics to build an efficient structure of team-based care among the physicians, utilizing each physicians’ area of interest and expertise in each disease field."
    //                         },
    //                         "5": {
    //                             text:
    //                                 "Lastly, in terms of appointment data, Panorama was able to analyze and identify the number of appointments and consults in conducted each month.",
    //                             table: {
    //                                 headers: [
    //                                     "2019",
    //                                     "Jan",
    //                                     "Feb",
    //                                     "Mar",
    //                                     "Apr",
    //                                     "May",
    //                                     "Jun",
    //                                     "Jul",
    //                                     "Aug",
    //                                     "2019 Total"
    //                                 ],
    //                                 body: [
    //                                     [
    //                                         "Airdrie Consult",
    //                                         "0",
    //                                         "6",
    //                                         "2",
    //                                         "2",
    //                                         "2",
    //                                         "3",
    //                                         "3",
    //                                         "1",
    //                                         "19"
    //                                     ],
    //                                     [
    //                                         "Airdrie Follow Up",
    //                                         "7",
    //                                         "3",
    //                                         "7",
    //                                         "4",
    //                                         "3",
    //                                         "3",
    //                                         "12",
    //                                         "3",
    //                                         "42"
    //                                     ],
    //                                     [
    //                                         "Bariatric Consult",
    //                                         "3",
    //                                         "2",
    //                                         "0",
    //                                         "0",
    //                                         "3",
    //                                         "0",
    //                                         "2",
    //                                         "3",
    //                                         "13"
    //                                     ],
    //                                     [
    //                                         "DEP Consult",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "2",
    //                                         "1",
    //                                         "3",
    //                                         "6"
    //                                     ],
    //                                     [
    //                                         "DEP Follow Up",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "1",
    //                                         "6",
    //                                         "8",
    //                                         "15"
    //                                     ],
    //                                     [
    //                                         "Diabetes Consult",
    //                                         "11",
    //                                         "16",
    //                                         "14",
    //                                         "7",
    //                                         "11",
    //                                         "10",
    //                                         "8",
    //                                         "4",
    //                                         "81"
    //                                     ],
    //                                     [
    //                                         "Diabetes Education Consults",
    //                                         "10",
    //                                         "5",
    //                                         "3",
    //                                         "0",
    //                                         "6",
    //                                         "4",
    //                                         "0",
    //                                         "0",
    //                                         "28"
    //                                     ],
    //                                     [
    //                                         "Diabetes Education Follow Up",
    //                                         "10",
    //                                         "11",
    //                                         "7",
    //                                         "7",
    //                                         "15",
    //                                         "5",
    //                                         "0",
    //                                         "0",
    //                                         "55"
    //                                     ],
    //                                     [
    //                                         "Diabetes Follow Up",
    //                                         "32",
    //                                         "16",
    //                                         "18",
    //                                         "18",
    //                                         "34",
    //                                         "21",
    //                                         "21",
    //                                         "30",
    //                                         "190"
    //                                     ],
    //                                     [
    //                                         "Endo Consult",
    //                                         "4",
    //                                         "9",
    //                                         "2",
    //                                         "5",
    //                                         "4",
    //                                         "6",
    //                                         "1",
    //                                         "5",
    //                                         "36"
    //                                     ],
    //                                     [
    //                                         "Endo Follow Up",
    //                                         "24",
    //                                         "15",
    //                                         "16",
    //                                         "16",
    //                                         "28",
    //                                         "22",
    //                                         "17",
    //                                         "15",
    //                                         "153"
    //                                     ],
    //                                     [
    //                                         "Group Class",
    //                                         "0",
    //                                         "1",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "1"
    //                                     ],
    //                                     [
    //                                         "Lipid Consult",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "1",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "1"
    //                                     ],
    //                                     [
    //                                         "Lipid Follow Up",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "1",
    //                                         "1",
    //                                         "2",
    //                                         "0",
    //                                         "4"
    //                                     ],
    //                                     [
    //                                         "Research Appointment",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "3",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "3"
    //                                     ],
    //                                     [
    //                                         "Thyroid Consult",
    //                                         "4",
    //                                         "2",
    //                                         "3",
    //                                         "2",
    //                                         "2",
    //                                         "1",
    //                                         "1",
    //                                         "2",
    //                                         "17"
    //                                     ],
    //                                     [
    //                                         "Urgent DEP Consult",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "1",
    //                                         "0",
    //                                         "1"
    //                                     ],
    //                                     [
    //                                         "Urgent DEC",
    //                                         "0",
    //                                         "0",
    //                                         "1",
    //                                         "1",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "2"
    //                                     ],
    //                                     [
    //                                         "Urgent Endo Follow Up",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "1",
    //                                         "1"
    //                                     ],
    //                                     [
    //                                         "Weight Management 1:1",
    //                                         "0",
    //                                         "1",
    //                                         "0",
    //                                         "0",
    //                                         "1",
    //                                         "1",
    //                                         "0",
    //                                         "0",
    //                                         "3"
    //                                     ],
    //                                     [
    //                                         "(blank)",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0",
    //                                         "0"
    //                                     ],
    //                                     [
    //                                         "Grand Total",
    //                                         "105",
    //                                         "87",
    //                                         "73",
    //                                         "65",
    //                                         "111",
    //                                         "80",
    //                                         "75",
    //                                         "75",
    //                                         "671"
    //                                     ]
    //                                 ]
    //                             }
    //                         },
    //                         "6": {
    //                             text:
    //                                 "This data was important for C-Endo to see, as based on this, they saw confidently which months were the busiest, as well as the kind of consults that occured each month. This had a direct impact on resource and vacation allocation based on the month of the year."
    //                         },
    //                         "7": {
    //                             title: "Triage level efficiency",
    //                             text:
    //                                 "Panorama also analyzed the efficiency of C-Endo's triage system.",
    //                             table: {
    //                                 headers: [
    //                                     "Triage Type",
    //                                     "Average of Difference in days"
    //                                 ],
    //                                 body: [
    //                                     ["Level 1", "15.15"],
    //                                     ["Level 1-2", "25.35"],
    //                                     ["Level 2", "71.27"],
    //                                     ["Level 2-3", "88.19"],
    //                                     ["Level 3", "83.96"],
    //                                     ["Level 3-4", "105.70"],
    //                                     ["Level 4", "105.51"],
    //                                     ["No Triage Level", "103.75"],
    //                                     ["Clinical Total Average", "75.72"]
    //                                 ]
    //                             }
    //                         },

    //                         "8": {
    //                             text:
    //                                 "In this analysis, C-Endo has demonstrated a highly efficient system in prioritizing patients by triage level, as the number of days it took to be seen decreases dramatically as the triage level decreases (increasing in severity). Furthermore, this analysis provided an accurate number of days it takes a patient to be seen based on triage level. Based on this information, the clinic can see whether each triage level meets their standards, and provided them with a direction to improve."
    //                         }
    //                     }
    //                 },
    //                 "2": {
    //                     title: "Diabetes Analysis:",
    //                     list: {
    //                         "1": {
    //                             text:
    //                                 "We analyzed all patients diagnosed with diabetes and categorized them into different subcategories of diabetes.",
    //                             table: {
    //                                 headers: ["Dx", "Count"],
    //                                 body: [
    //                                     ["Diabetes", "126"],
    //                                     ["Diabetes Gestational", "68"],
    //                                     ["Diabetes Insipidus", "4"],
    //                                     ["Diabetes Mellitus", "77"],
    //                                     ["Diabetes Mellitus (LADA)", "22"],
    //                                     ["Diabetes Mellitus (type 1)", "357"],
    //                                     ["Diabetes Mellitus (type 2)", "1681"],
    //                                     ["Prediabetes", "91"],
    //                                     ["Grand Total", "2426"]
    //                                 ]
    //                             }
    //                         },
    //                         "2": {
    //                             text:
    //                                 "In this clinic’s case, we identified that most of the clinic’s diabetic population is composed of type 2 diabetes (almost 70% of the entire diabetic population). A clear picture of the diabetic patient composition allows the clinic to make management decisions such as focusing more effort and personnel into managing type II patients, proportional to the rest of the diabetics. It allowed the clinic to split and allocate resources based on the proportionality of each diabetic subtypes."
    //                         },
    //                         "3": {
    //                             title: "A1c related value",
    //                             text:
    //                                 "We pulled the latest A1c values from all patients and grouped them to either greater, equal to, or less than 7.",
    //                             table: {
    //                                 headers: ["Results", "Count"],
    //                                 body: [
    //                                     ["At 7", "123"],
    //                                     ["Over 7", "2581"],
    //                                     ["Under 7", "1106"],
    //                                     ["Grand Total", "3810"]
    //                                 ]
    //                             }
    //                         },
    //                         "4": {
    //                             text:
    //                                 "In this clinic, we were able to identify 2581 patients with their latest A1c values greater than 7. As a practice striving for the highest quality care for their patients, they have implemented strategies such as recalling these patients back and putting them on a management plan to reduce their A1c levels to less than 7."
    //                         },
    //                         "5": {
    //                             text:
    //                                 "We also analyzed and identified the first A1c value and the last for each patient, and looked at whether the A1c values have been increasing or decreasing.",
    //                             table: {
    //                                 headers: [
    //                                     "Results",
    //                                     "Count of Increase or Decrease",
    //                                     "Percentage of Increase or Decrease"
    //                                 ],
    //                                 body: [
    //                                     ["Decrease", "1596", "59.57%"],
    //                                     ["Increase", "977", "36.47%"],
    //                                     ["No Change", "106", "3.96%"],
    //                                     ["Grand Total", "2679", "100.00%"]
    //                                 ]
    //                             }
    //                         },
    //                         "6": {
    //                             text:
    //                                 "In this clinic, we identified that the number of patients who are experiencing a decrease of A1c values when comparing their first and latest A1c values constitute near 60% of all patients. This demonstrates the clinic’s effective ability to drive their patients’ A1c values down but also demonstrated to the clinic room for improvement. This room for improvement provided evidence to the clinic that strategies to drive the values down are warranted. Based on the analysis, we were able to inform the clinic who the patients are from the 40% who experienced an increase in A1c or no change, which are worth investigating and implementing workflow changes to provide the most patient-centred care possible."
    //                         },
    //                         "7": {
    //                             title: "LDL related values",
    //                             text:
    //                                 "Similar to the A1c analysis, we have also analyzed the latest LDL values of all diabetic patients in this practice and compared it relative to the value of 2, as according to the Diabetes Canada guidelines, diabetic patients should aim to drive their LDL values to below 2.",
    //                             table: {
    //                                 headers: ["Results", "Count"],
    //                                 body: [
    //                                     ["At 2", "21"],
    //                                     ["Over 2", "1577"],
    //                                     ["Under 2", "2269"],
    //                                     ["Grand Total", "3867"]
    //                                 ]
    //                             }
    //                         },
    //                         "8": {
    //                             text:
    //                                 "In this clinic, we found that almost 60% of diabetic patients have their LDL values less than 2. This indicates a well-run process at driving down LDL values. However, we were able to recommend 1577 patients to the clinic to implement strategies to help with driving their values down. <b>In fact, the clinic was so dedicated to providing the best care for their patients, they have decided to open a new lipid clinic to help address these patients. This was made possible through careful analytics that demonstrated a need for the clinic, which ultimately improves patient care.</b>"
    //                         },
    //                         "9": {
    //                             title: "Prescription Pattern",
    //                             text:
    //                                 "We also helped the clinic identify what kind of patients were receiving Novorapid 100 or Tresiba Flextouch 100.",
    //                             img: ""
    //                         },
    //                         "10": {
    //                             text:
    //                                 "We found that for Novorapid 100, the clinic predominantly prescribed to type II diabetic patients, and for Tresiba Flextouch 100, the clinic predominantly prescribed to female patients with type I diabetes. This information allowed the clinic to see their prescription patterns, and determine whether they are prescribing according to best practice, and which aspect to improve if not."
    //                         }
    //                     },
    //                     "3": {
    //                         title: "Hypertension Analysis:",
    //                         list: {
    //                             "1": {
    //                                 title: "Hypertension diagnosis",
    //                                 text:
    //                                     "We first identified hypertensive patients through a variety of parameters that confidently tells us that these patients should be diagnosed with hypertension. Parameters such as Hypertension Canada Diagnostic criteria, history of having been billed hypertension-related bills, mention of a hypertension diagnosis in free text form, etc. We then looked at the disease profiles of all these patients to see if a proper hypertension diagnosis along with its 401 ICD9 code has been entered.",
    //                                 table: {
    //                                     headers: [
    //                                         "",
    //                                         "Count of Diagnosis Code"
    //                                     ],
    //                                     body: [
    //                                         ["401", "2"],
    //                                         ["No Code", "2173"],
    //                                         ["Grand Total", "2175"]
    //                                     ]
    //                                 }
    //                             },
    //                             "2": {
    //                                 title:
    //                                     "Lab values of diabetic patients withh comorbid hypertension",
    //                                 text:
    //                                     "We analyzed the A1c values of patients with both diabetes and hypertension.",
    //                                 table: {
    //                                     headers: ["Results", "Count"],
    //                                     body: [
    //                                         ["At 7", "33"],
    //                                         ["Over 7", "635"],
    //                                         ["Under 7", "738"],
    //                                         ["Grand Total", "1406"]
    //                                     ]
    //                                 }
    //                             },
    //                             "3": {
    //                                 text:
    //                                     "We were able to identify 738 out of 1406 (52%) patients that the clinic was able to keep A1c values under 7. This demonstrated the clinic’s ability to driving A1c values to target in patients with both diabetes and hypertension. Furthermore, through our analytics, the clinic was able to see 635 out of 1406 hypertensive and diabetic patients (45%) who have their A1c values out of the target. As a practice striving for the best patient care, this insight quickly allowed them to implement strategies to address the patients’ blood pressure values and A1c values, as both values are important in decreasing morbidity and mortality rates among diabetic patients."
    //                             },
    //                             "4": {
    //                                 text:
    //                                     "Similar to A1c values, we also looked at the LDL values of patients with both diabetes and hypertension.",
    //                                 table: {
    //                                     headers: ["Results", "Count"],
    //                                     body: [
    //                                         ["At 2", "4"],
    //                                         ["Over 2", "654"],
    //                                         ["Under 2", "865"],
    //                                         ["Grand Total", "1523"]
    //                                     ]
    //                                 }
    //                             },
    //                             "5": {
    //                                 text:
    //                                     "In this clinic, 865 out of 1523 (57%) patients have their LDL values less than 2, which demonstrates an efficient process at driving LDL values down. Despite this efficient process, we were able to identify 654 out of 1523 (43%) hypertensive and diabetic patients who have their LDL values greater than 2 (out of target). Since this clinic specializes in diabetic and endocrine care, its core focus was not cardiovascular and lipid care. However, to ensure their patients get the best care possible, <b>the clinic decided to open a new lipid clinic that is equipped to cater to these patients with LDL out of target after reviewing our analytics. This provides resources to drastically improve patient care.</b>"
    //                             },
    //                             "6": {
    //                                 text:
    //                                     "Please note this A1c and LDL analysis may look similar to the previous A1c and LDL analysis; however, this one focuses on patients with diabetes AND hypertension, while the previous one looks at patients with only diabetes and not hypertension."
    //                             }
    //                         }
    //                     },
    //                     "4": {
    //                         title:
    //                             "Appointment History in Osteoporosis Patients",
    //                         list: {
    //                             "1": {
    //                                 text:
    //                                     "We analyzed the appointment counts of all osteoporosis patients",
    //                                 table: {
    //                                     headers: [
    //                                         "Row Labels",
    //                                         "Count of Appt in the last 13 months",
    //                                         "Count of Appt in the l3 months (%)"
    //                                     ],
    //                                     body: [
    //                                         ["No", "45", "25.71%"],
    //                                         ["Yes", "130", "74.29%"],
    //                                         ["Grand Total", "175", "100.00%"]
    //                                     ]
    //                                 }
    //                             },
    //                             "2": {
    //                                 text:
    //                                     "In this clinic, we were able to identify 45 patients with osteoporosis that never had an appointment 13 months ago (from the day of the analysis). These 45 patients are at risk of a debilitating fracture if not seen and addressed properly. With this information, the clinic is able to recall these patients back to ensure they are seen, and the proper management plan is in place to reduce the risks of fractures."
    //                             },
    //                             "3": {
    //                                 text:
    //                                     "As seen in this comprehensive case study, with the help and expertise of JustPractice in conducting deep data analysis on clinical data, C-Endo is able to drastically improve their operations, and more importantly, provide better care to their patients as they strive to be the most patient-centred practice."
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    // ]
    // }
];

export default CaseStudiesData;
