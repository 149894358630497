import React from "react";
import "../styles/PrivacyPolicy.scss";

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <div className="header-wrapper">
                <div className="header">
                    <h1 className="header-title">Privacy Policy</h1>
                </div>
            </div>
            <div className="content">
                <h5 className="text">
                    JustPractice Technologies Inc. (“<b>JustPractice</b>”, “
                    <b>we</b>”, “<b>us</b>” or “<b>our</b>”) is committed to
                    protecting the privacy and confidentiality of personal
                    information in our custody and control. This Privacy Policy
                    explains how we collect, use, disclose and protect personal
                    information in the course of providing our services and
                    through our website at justpractice.ca (the “<b>Site</b>”).
                </h5>
                <h3 className="info-title">
                    Collection and Use of Personal Information
                </h3>
                <h5 className="text">
                    We provide tools to help hospitals, clinics, physicians and
                    other health care providers (“<b>Clients</b>”), manage their
                    practices and improve patient care by providing tools to
                    analyze and draw insights from patient data. In doing so, we
                    may collect personal information relating to Clients and
                    their employees and representatives (“<b>Client Data</b>”).
                    In connection with our Client’suse of our tools, we may also
                    process and analyze personal health information relating to
                    our Client’s patients (“
                    <b>Patient Data</b>”). We process Patient Data on behalf of
                    our Clients as their service provider. Our Clients are
                    accountable for the Patient Data that we process on their
                    behalf. If you are a patient of one of our Clients and have
                    questions about how your Patient Data is handled, please
                    contact the Client directly.
                </h5>
                <h3 className="info-title">Client data</h3>
                <h5 className="text">
                    The Client Data we collect includes name and contact
                    information, username and password, payment card
                    information, and information about Client needs, medical
                    specialties, practice (e.g. location, size, type),
                    information systems and preferences.
                </h5>
                <h5 className="text">We use Client Data to:</h5>
                <ul className="list">
                    <li>understand Client needs;</li>
                    <li>
                        determine suitability for our products and services;
                    </li>
                    <li>provide products or services as requested</li>
                    <li>processing payments;</li>
                    <li>
                        improve our products and services through surveys
                        conducted directly by JustPractice or by approved third
                        parties;
                    </li>
                    <li>
                        inform Clients and their employees and representatives
                        about products, services, information and events that
                        may be of interest to them;
                    </li>
                    <li>comply with the applicable law; and</li>
                    <li>meet contractual or legal requirements.</li>
                </ul>
                <h3 className="info-title">
                    Information We Collect Automatically
                </h3>
                <h5 className="text">
                    We automatically collect information about your computer or
                    mobile device when you visit our Site. This information may
                    include your IP address, browser and device type, access
                    times, the web page from which you came, the regions from
                    which you navigate the webpage and the webpage(s) you access
                    (as applicable).
                </h5>
                <h5 className="text">
                    We may also use cookies and similar tools to improve your
                    experience on the Site. Cookies aresmall text files placed
                    on your device when you visit a website, in order to track
                    use of the site and to improve your user experience. For
                    example, we use cookies to remember your log-in session so
                    you can move easily within the Site, and to track the number
                    of unique visits to our Site. We may also use cookies and
                    similar tools to serve you with tailored content or
                    advertisingon our Site or when you visit other sites. Most
                    computer and some mobile web browsers automatically accept
                    cookies but, if you prefer, you can change your browser to
                    prevent that or to notify you each time a cookie is set.
                    Please note however that, by blocking or deleting cookies,
                    you may not be able to take full advantage of the Site.
                </h5>
                <h3 className="info-title">
                    Disclosure of Personal Information
                </h3>
                <h5 className="text">
                    We may transfer personal information to service providers
                    that provide certain services on our behalf, such as data
                    hosting and processing, marketing, analytics, information
                    technology services, call center, payment processing. Some
                    of these service providers may be located outside of Canada,
                    including in the United States. While outside of Canada,
                    personal information will be subject to applicable foreign
                    laws, which may permit government and national security
                    agencies to access your information in certain circumstances
                </h5>
                <h5 className="text">
                    We may disclose personal information to parties in
                    connection with a corporate transaction, such as a sale,
                    financing or other disposition of all or part of our
                    business or assets, for the purposes of evaluating and/or
                    performing the transaction.
                </h5>
                <h5 className="text">
                    We may disclose personal information where permitted or
                    required by law, including to comply with a subpoena or
                    warrant, to law enforcement in connection with an
                    investigation, to protect our rights and the safety and
                    security of our employees, and to enforce our agreements and
                    collect debts owed to us.
                </h5>
                <h5 className="text">
                    Our Clients will have access to Patient Data relating to
                    their own patients. We are not responsible for our Client’s
                    handling of Patient Data. If you are a patient and have
                    questions about how your Patient Data is handled, please
                    contact the applicable Client directly.
                </h5>
                <h3 className="info-title">De-Identified Data</h3>
                <h5 className="text">
                    We use the information we collect, including Client Data,
                    Patient Data, and data collected automatically through tools
                    such as cookies, to create de-identified data that cannot be
                    associated with, or used to identify, a Client, user or
                    patient. We may use and share de-identified data with third
                    parties in order to improve products or services, create new
                    products orservices and for research and marketing purposes.
                </h5>
                <h3 className="info-title">
                    Retention of Personal Information
                </h3>
                <h5 className="text">
                    Personal information is maintained on our servers or those
                    of our service providers and is retained as long as
                    necessary for the fulfilment of the purposes for which it
                    was collected, in accordance with prudent business practices
                    on general document retention, or as otherwise required by
                    law.
                </h5>
                <h3 className="info-title">Safeguards</h3>
                <h5 className="text">
                    We protect personal information using safeguards that are
                    appropriate to the sensitivity of the information. These
                    safeguards may include measures to protect against
                    unauthorized access, alteration, disclosure or destruction
                    of personal information. We limit access to personal
                    information to our employees and service providers who
                    require access in connection with their role or function.
                    Each of our employees is responsible for maintaining the
                    confidentiality of all personal information to which they
                    have access. We keep our employees informed about our
                    policies and procedures for protecting personal information.
                    Despite these measures, since no system or security measure
                    is completely secure, it is possible that personal
                    information may belost, stolen or accessed without
                    authorization
                </h5>
                <h3 className="info-title">Your Choices</h3>
                <h5 className="text">
                    By providing us with personal information or otherwise using
                    our services, you consent to the collection, use and
                    disclosure of your personal information as described in this
                    privacy policy. If you provide us with personal information
                    relating to a third party (for example, if you are a
                    Clientand you provide us with Patient Data) we will assume
                    that you have obtained all necessary consents from the third
                    party to provide their personal information to us for the
                    purposes described in this privacy policy.
                </h5>
                <h5 className="text">
                    You may unsubscribe from our promotional email
                    communications at any time by using the unsubscribe feature
                    included in those messages. Please note that if you
                    unsubscribe from our promotional email communications you
                    may still receive transactional or business-related
                    communications from us.
                </h5>
                <h5 className="text">
                    You may withdraw your consent to our collection, use or
                    disclosure of your personal information at any time, subject
                    to contractual and legal restrictions, by writing to us as
                    described below. Please note that if you withdraw your
                    consent, we may not be able to provide some or all of our
                    services to you.
                </h5>
                <h3 className="info-title">Access and Correction</h3>
                <h5 className="text">
                    You may request access to or correction of your personal
                    information in our custody or control, by writing to us as
                    described below. Your right to access and correct your
                    personal information is subject to applicable legal
                    restrictions. We may take appropriate steps to verify your
                    identity before providing access or making corrections.
                    Please note that if you are a patient of one of our Clients,
                    you must make all such requests directly to the applicable
                    Client.
                </h5>
                <h3 className="info-title">Contact</h3>
                <h5 className="text">
                    Please direct all questions, inquiries and requests to our
                    Privacy Officers – Jorden Lo at jorden.lo@justpractice.ca or
                    Danny Lo at danny.lo@justpractice.ca
                </h5>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
