import React from "react";
import "../../styles/Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faLinkedin,
    faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-info">
                    <img
                        className="logo-img"
                        src="/Logo-Outline.svg"
                        alt="logo"
                    />
                    <span>10013 River Dr #2030, Richmond, BC V6X 1Z2</span>
                    <div className="links">
                        <a href="/contact_us">
                            <u>Contact Us</u>
                        </a>
                        <a
                            href="https://panorama.justpractice.ca/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <u>Sign In</u>
                        </a>
                    </div>
                </div>
                <div className="footer-about">
                    <h3 className="title">ABOUT</h3>
                    <span className="content">
                        At JustPractice, we aim to help physicians everywhere to
                        manage and halt disease progression.
                    </span>
                </div>
                <div className="footer-nav">
                    <div className="links">
                        {
                            //TODO: Add links
                        }
                        <a href="/">WHY JUSTPRACTICE?</a>
                        <a href="/solutions">SOLUTIONS</a>
                        <a href="/resources">RESOURCES</a>
                    </div>
                    <div className="footer-media">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/justpracticemed/"
                        >
                            <FontAwesomeIcon
                                size="2x"
                                icon={faInstagram}
                                color="#fff"
                            />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/justpracticetech"
                        >
                            <FontAwesomeIcon
                                size="2x"
                                icon={faFacebookSquare}
                                color="#fff"
                            />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://ca.linkedin.com/company/jmj-medical-inc"
                        >
                            <FontAwesomeIcon
                                size="2x"
                                icon={faLinkedin}
                                color="#fff"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <span>
                    ©2020 JustPractice Technologies Inc. All rights reserved.
                </span>
                <div className="copyright-links">
                    {/* <a href="/">
                        <u>Terms of Service</u>
                    </a>
                    <a href="/">
                        <u>Security</u>
                    </a>
                    <a href="/">
                        <u>Sitemap</u>
                    </a> */}
                    <a href="/privacy_policy">
                        <u>Privacy Policy</u>
                    </a>
                    <a href="/user_confidentiality">
                        <u>User Confidentiality</u>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
