import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import "../styles/Error.scss";

const Error = () => {
    return (
        <div className="error-page">
            <FontAwesomeIcon
                className="error-icon"
                size="6x"
                icon={faExclamation}
            />
            <h1 className="title">Page not found</h1>
            <h3 className="text">
                We're sorry, we couldn't find the page you requested.
            </h3>
            <h3 className="text">
                If you feel something is missing that should be here,{" "}
                <a href="/contact_us">contact us</a>!
            </h3>
        </div>
    );
};

export default Error;
