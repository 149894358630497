import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "../../styles/Navbar.scss";
import StyledBtn from "../StyledBtn/StyledBtn";

//uses anchor tags instead of link for page refresh
const Navbar = (props) => {
    const [dropdown, setDropdown] = useState(false);
    const [expand, setExpand] = useState(false);
    const [fixedNav, setFixedNav] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const navOnScroll = (e) => {
        const window = e.currentTarget;

        if (scrollY > window.scrollY && scrollY > 80) {
            setFixedNav(true);
        } else if (scrollY < window.scrollY) {
            setFixedNav(false);
            setDropdown(false);
        }
        setScrollY(window.scrollY);
    };

    const expandDropdown = (e) => {
        setExpand(!expand);
    };

    useEffect(() => {
        window.addEventListener("scroll", navOnScroll);
        return () => {
            window.removeEventListener("scroll", navOnScroll);
        };
    });

    return (
        <div className={`navbar ${fixedNav ? "active" : ""}`}>
            <div className="left">
                <a href="/" className="logo">
                    <img
                        className="logo-img"
                        src="/Logo-Outline.svg"
                        alt="logo"
                    />
                </a>
                <div className="nav-links">
                    <Link
                        className={
                            props.location.pathname === "/" ? "active" : null
                        }
                        to="/"
                    >
                        WHY JUSTPRACTICE?
                    </Link>

                    <Link
                        className={
                            props.location.pathname === "/resources"
                                ? "active"
                                : null
                        }
                        to="/resources"
                    >
                        RESOURCES
                    </Link>
                    <div onClick={() => expandDropdown()}>
                        SOLUTIONS &#9660;
                        <div
                            className={
                                expand
                                    ? "drop-links show-dropdown"
                                    : "drop-links hide-dropdown"
                            }
                        >
                            <Link
                                className={
                                    props.location.pathname ===
                                    "/medical_associations"
                                        ? "active"
                                        : null
                                }
                                to="/medical_associations"
                            >
                                MEDICAL ASSOCIATIONS
                            </Link>
                            <Link
                                className={
                                    props.location.pathname ===
                                    "/patient_medical_home_teams"
                                        ? "active"
                                        : null
                                }
                                to="/patient_medical_home_teams"
                            >
                                PATIENT MEDICAL HOME TEAMS
                            </Link>
                            <Link
                                className={
                                    props.location.pathname ===
                                    "/pharmaceutical_corporations"
                                        ? "active"
                                        : null
                                }
                                to="/pharmaceutical_corporations"
                            >
                                PHARMACEUTICAL CORPORATIONS
                            </Link>
                            <Link
                                className={
                                    props.location.pathname ===
                                    "/guideline_societies"
                                        ? "active"
                                        : null
                                }
                                to="/guideline_societies"
                            >
                                GUIDELINE SOCIETIES
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="account-tools">
                    {/* <a href="/contact_us">
                        <u>Contact Us</u>
                    </a> */}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://panorama.justpractice.ca/"
                    >
                        <u>Sign In</u>
                    </a>
                </div>
                <div className="get-started">
                    <a href="/contact_us">
                        <StyledBtn>GET STARTED</StyledBtn>
                    </a>
                </div>
            </div>
            <div className={`mobile ${fixedNav ? "active" : ""}`}>
                <div className="dropdown-controller">
                    <div className="left">
                        <a href="/" className="logo">
                            <img
                                className="logo-img"
                                src="/Logo-Outline.svg"
                                alt="logo"
                            />
                        </a>
                    </div>
                    <div className="right">
                        <button
                            className="hamburger"
                            onClick={() => {
                                setDropdown(!dropdown);
                            }}
                        >
                            <img
                                className="hamburger-img"
                                src="/hamburger.png"
                                alt="Hamburger"
                            />
                        </button>
                    </div>
                </div>
                <div className={"dropdown " + (dropdown ? "active" : "")}>
                    <div className="nav-links">
                        <a href="/">WHY JUSTPRACTICE?</a>
                        <a href="/resources">RESOURCES</a>
                        <div onClick={() => expandDropdown()}>
                            <h3>SOLUTIONS &#9660;</h3>
                            <div
                                className={
                                    expand
                                        ? "drop-links show-dropdown"
                                        : "hide drop-links hide-dropdown"
                                }
                            >
                                <a href="/medical_associations">
                                    MEDICAL ASSOCIATIONS
                                </a>
                                <a href="/patient_medical_home_teams">
                                    PATIENT MEDICAL HOME TEAMS
                                </a>
                                <a href="/pharmaceutical_corporations">
                                    PHARMACEUTICAL CORPORATIONS
                                </a>
                                <a href="/guideline_societies">
                                    GUIDELINE SOCIETIES
                                </a>
                            </div>
                        </div>
                        <a href="/contact_us">CONTACT US</a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://panorama.justpractice.ca/"
                        >
                            SIGN IN
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter((props) => <Navbar {...props} />);
