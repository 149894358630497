import React, { useState } from "react";
import { CaseStudiesData } from "../_data";
import { useParams } from "react-router-dom";
import { BlueOverlay } from "../components";

import "../styles/CaseStudy.scss";

const CaseStudy = () => {
    let { id } = useParams();

    const [caseStudy] = useState(
        CaseStudiesData.filter((caseStudy) => caseStudy.id === parseInt(id))[0]
    );

    const parseToHTML = (arr) => {
        return (
            <React.Fragment>
                {React.createElement(
                    "div",
                    { className: "case-study-content" },
                    arr.map((data, key) => {
                        let keys = Object.keys(data);
                        let resultChildren = [];
                        for (let i = 0; i < keys.length; i++) {
                            switch (keys[i]) {
                                case "title":
                                    resultChildren.push(
                                        createElement(
                                            "h3",
                                            { key: i, className: "title" },
                                            data[keys[i]]
                                        )
                                    );
                                    break;
                                case "linkedtext":
                                    resultChildren.push(
                                        createElement(
                                            "linked-h5",
                                            {
                                                key: i,
                                                className: "text content",
                                            },
                                            data[keys[i]]
                                        )
                                    );
                                    break;
                                case "subtitle":
                                    resultChildren.push(
                                        createElement(
                                            "h5",
                                            { key: i, className: "sub-title" },
                                            data[keys[i]]
                                        )
                                    );
                                    break;
                                case "text":
                                    resultChildren.push(
                                        createElement(
                                            "h5",
                                            {
                                                key: i,
                                                className: "text content",
                                            },
                                            data[keys[i]]
                                        )
                                    );
                                    break;
                                case "image":
                                    resultChildren.push(
                                        createElement(
                                            "image",
                                            {
                                                key: i,
                                                className: "image",
                                            },
                                            data[keys[i]]
                                        )
                                    );
                                    break;
                                case "list":
                                    resultChildren.push(
                                        createElement(
                                            "ol",
                                            {
                                                key: i,
                                                className: "list",
                                                type: keys[0],
                                            },
                                            data[keys[i]]
                                        )
                                    );
                                    break;
                                case "table":
                                    resultChildren.push(
                                        createElement("table", {
                                            key: i,
                                            className: "table",
                                        }),
                                        data[keys[i]]
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }
                        return resultChildren;
                    })
                )}
            </React.Fragment>
        );
    };

    const createElement = (type, attr, child) => {
        switch (type) {
            case "ol":
            case "ul":
                return createList(type, attr, child);
            case "image":
                return createImage(attr, child);
            case "table":
                return createTable(attr, child);
            case "linked-h5":
                return createText(attr, child);
            default:
                return React.createElement(type, attr, child);
        }
    };

    const createImage = (attr, child) => {
        // return React.createElement("img", { ...attr, src: `/${child.src}` });
        return (
            <div className={`image-wrapper`}>
                {child.map((data, key) => {
                    return (
                        <div className={`image-content ${data.type}`} key={key}>
                            <img
                                alt={attr.className}
                                className={attr.className}
                                src={`/${data.src}`}
                            />
                            <h3 className="sub-title">{data.fig}</h3>
                        </div>
                    );
                })}
            </div>
        );
    };

    const createText = (attr, child) => {
        let childLinks = [];
        for (let i = 0; i < child.links.length; i++) {
            let linkInfo = child.links[i];
            childLinks.push(
                React.createElement(
                    "a",
                    { key: i, className: "link", href: linkInfo.href },
                    linkInfo.text
                )
            );
        }
        let splitArr = child.text.split(/\B\$\w+/);
        let joinedArr = splitArr.map((e, i) => {
            return [e, childLinks[i]];
        });
        return React.createElement(
            "h5",
            { ...attr, href: child.href },
            joinedArr
        );
    };

    //uses recursion in case of deep nesting for list
    const createList = (type, attr, child) => {
        let childListArr = [];
        let keys = Object.keys(child);

        for (let i = 0; i < keys.length; i++) {
            if (child[keys[i]].list) {
                childListArr.push(
                    React.createElement(
                        "li",
                        { key: i, type: type === "ol" ? keys[0] : null },
                        [
                            child[keys[i]].title,
                            createList(type, attr, child[keys[i]].list),
                        ]
                    )
                );
            } else {
                let listChild = child[keys[i]];
                if (listChild.links) {
                    let childLinks = [];
                    for (let i = 0; i < listChild.links.length; i++) {
                        let linkInfo = listChild.links[i];
                        childLinks.push(
                            React.createElement(
                                "a",
                                {
                                    key: i,
                                    className: "link",
                                    href: linkInfo.href,
                                },
                                linkInfo.text
                            )
                        );
                    }
                    let splitArr = listChild.text.split(/\B\$\w+/);
                    let joinedArr = splitArr.map((e, i) => {
                        return [e, childLinks[i]];
                    });
                    listChild = joinedArr;
                }
                console.log(listChild);
                childListArr.push(
                    React.createElement(
                        "li",
                        { key: i, type: type === "ol" ? keys[0] : null },
                        listChild.text ? listChild.text : listChild
                    )
                );
            }
        }

        return React.createElement(type, attr, childListArr);
    };

    const createTable = (attr, child) => {
        console.log(child);
        return (
            <table>
                <thead>{}</thead>
                <tbody></tbody>
            </table>
        );
    };
    return (
        <div className="case-study">
            <div className="case-study-header-wrapper">
                <BlueOverlay src="/case-study.jpg" flip center>
                    <div className="case-study-header">
                        <h1 className="header-title">{caseStudy.title}</h1>
                        <h3 className="header-description case-study-category">
                            {caseStudy.category}
                        </h3>
                    </div>
                </BlueOverlay>
            </div>
            <div className="case-study-content-wrapper">
                {caseStudy.content && parseToHTML(caseStudy.content)}
            </div>
        </div>
    );
};

export default CaseStudy;
