const GuidelineSocieitiesData = {
    header: {
        headerTitle: "Guideline  Societies",
        headerDescription:
            "Provide an environment that will allow your physicians to practice according to guidelines.",
    },
    benefit1: {
        headline1:
            "Help your physicians adhere and practice according to your guidelines.",
    },
    info1: {
        text: `Clinical practice guidelines have the potential
        to improve the clinical decision-making process
        for physicians. They provide consistent
        recommendations to physicians based on the
        latest research and evidence. Furthermore,
        through a process of thorough and critical
        appraisal of scientific evidence, guidelines are
        able to draw the physicians’ attention to
        ineffective and outdated practices traditionally
        done in the past. Physicians who want to provide
        the best care for their patients should at the
        very least consult the most updated guidelines
        for their specialty, and rationale through their
        decision-making process for their patients with
        the latest recommendations in mind.`,
        headerText: "Values a guideline society can expect",
        benefits: [
            {
                text: "Keep the physicians updated on the latest guidelines",
            },
            {
                text: "Translation from guidelines to practice",
            },
            {
                text: "Understand physician concerns with guidelines",
            },
            {
                text:
                    "Aggregate evidence on why physicians deviate from guidelines",
            },
        ],
    },
    quote: {
        headerText: `"It was a pleasure to work with this company.
        They made the process easy and they were very
        approachable."`,
        quoter: "Dr. Isaiah Bregman - Broadway Plaza Family Practice",
    },
    info2: {
        infoTitle: `Help your physicians practice by your
        guidelines`,
        subDescriptionTitle1: `Stay updated`,
        subDescriptionText1: `Guidelines are constantly changing to strive for best practices. If
        guidelines are not always changing, recommendations will not be derived from critically appraising the latest scientific evidence.
        This allows physicians to provide the best care for their patients with the most updated evidence.
        Paradoxically, the more frequently recommendations are updated, the harder it is for physicians to keep track of what
        the latest recommendations are. Through Panorama's ability to reverse engineer guideline parameters, patients with care gaps
        can always be identified based on the latest guidelines and addressed appropriately.`,
        subDescriptionTitle2: `Translate guidelines into point of care`,
        subDescriptionText2: `Physicians are trained to always critically
        appraise scientific research and evidence.
        Due to this nature, guidelines are always
        highly detailed and comprehensive to allow
        physicians to see the thought process that
        went behind in synthesizing the guideline
        recommendations. This can make it difficult
        for physicians to constantly translate all
        strong recommendations for their patients in
        their practice. Being simple and intuitive
        to use, Panorama allows physicians to see
        exactly which patients are not being cared
        for according to guidelines, and directly
        implement strategies to address these gaps
        to cut straight through into practicing the
        guidelines directly.`,
    },
    clinicsAd: {
        headerText:
            "Join these partners using JustPractice to transform their business",
        logoList: [
            {
                logoSrc: "divisions_pic.png",
                name: "Divisions of Family Practice",
            },
            {
                logoSrc: "edocs.png",
                name: "Electronic Medical Record",
            },
            {
                logoSrc: "logo-camrosepcn.png",
                name: "Camrose Primary Care Network",
            },
            {
                logoSrc: "logo-gpsc.png",
                name: "General Practice Services Committee",
            },
        ],
    },
    info3: {
        infoTitle: "Improving guideline adoption",
        subDescriptionTitle1:
            "Seeing who is deviating away from guidelines and why",
        subDescriptionText1: `As busy physicians, it can be very easy to
        unintentionally deviate away from
        guidelines. Sometimes physicians
        intentionally deviate away from guidelines
        based on a sound decision-making process,
        and individualizes care for their patients
        where recommendations don’t apply to them.
        However, often times physicians
        unintentionally deviate away from guidelines
        when they don’t mean to. This can happen due
        to a number of reasons, such as not knowing
        the latest recommendations or not knowing
        which patients now have care gaps after
        guidelines have been updated. With
        Panorama’s ability to aggregate physician
        data, it gives societies the ability to
        identify where resources and education need
        to be deployed to improve physician
        guideline adherence. Furthermore, Panorama
        can help answer questions related to why
        physicians are intentionally deviating away
        from guidelines based on data. This
        information will allow societies to work with
        vendors, industry partners, and regulators
        to provide an environment for physicians to
        practice according to the latest guidelines.`,
    },
};

export default GuidelineSocieitiesData;
