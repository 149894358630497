import React, { useState } from "react";
import { CaseStudiesData } from "../_data";

import "../styles/CaseStudies.scss";
import { BlueOverlay } from "../components";

const CaseStudies = () => {
    const [caseStudiesData, setCaseStudiesData] = useState(CaseStudiesData);

    const filterSearch = (query, data) => {
        query = query.toLowerCase();
        const filtered = data.filter(
            (info) =>
                info.title.toLowerCase().includes(query) ||
                info.description.toLowerCase().includes(query) ||
                info.author.toLowerCase().includes(query) ||
                info.date.toLowerCase().includes(query)
        );

        setCaseStudiesData(filtered);
    };

    return (
        <div className="case-studies">
            <div className="case-studies-header-wrapper">
                <BlueOverlay src="case-studies.jpg" flip center>
                    <div className="case-studies-header">
                        <h1 className="header-title">Case Studies</h1>
                        <h3 className="header-description">
                            Discover how JustPractice can help with your
                            practice.
                        </h3>
                    </div>
                </BlueOverlay>
            </div>
            <div className="case-studies-tools">
                <div className="search-tool">
                    <input
                        placeholder="Search..."
                        type="search"
                        className="input-box"
                        onChange={(e) =>
                            filterSearch(e.target.value, CaseStudiesData)
                        }
                    />
                </div>
                {/* <div className="sort">
                    <label>Sort By </label>
                    <select onChange={e => setDateDirection(e.target.value)}>
                        <option value="asc">Date (newest)</option>
                        <option value="desc">Date (oldest)</option>
                    </select>
                </div> */}
            </div>
            <div className="case-studies-list-wrapper">
                {/* 
                    in case we get enough case studies that we separate by category
                <ul className="categories-list">
                    <li className="active">Stuff</li>
                    <li>Other Stuff</li>
                    <li>Final Stuff</li>
                </ul> */}
                <ul className="case-studies-list">
                    {caseStudiesData.reverse().map((data, key) => {
                        return (
                            <li className="case-study-item" key={key}>
                                <a href={`/case_studies/${data.id}`}>
                                    {data.src !== "/" ? (
                                        <div
                                            className="study-img"
                                            style={{
                                                backgroundImage: `url(${data.src})`,
                                            }}
                                        ></div>
                                    ) : (
                                        <div
                                            className="image"
                                            style={{
                                                width: "20em",
                                                height: "10em",
                                                backgroundColor: "red",
                                            }}
                                        ></div>
                                    )}
                                    <div className="description">
                                        <div className="description-content">
                                            <h5 className="description-category">
                                                {data.category}
                                            </h5>
                                            <h3 className="description-title">
                                                {data.title}
                                            </h3>
                                            <h5 className="description-content">
                                                {data.description}
                                            </h5>
                                            <p className="learn-more">
                                                Learn More
                                            </p>
                                        </div>
                                        <div className="meta">
                                            <h5 className="author">
                                                {data.author}
                                            </h5>
                                            <h5 className="date">
                                                {data.date}
                                            </h5>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default CaseStudies;
