import React from "react";
import "../../styles/BlueOverlay.scss";

const BlueOverlay = props => {
    const { src, children, center, flip } = props;
    //TODO: MAKE BETTER DONT PUT STYLE IN HTML
    return (
        <div className="blue-overlay">
            <div
                className={
                    "overlay-img" +
                    `${center ? " center " : ""}` +
                    `${flip ? " flip " : ""}`
                }
                style={{ backgroundImage: `url(${src})` }}
            ></div>
            <div className="overlay-children">{children}</div>
        </div>
    );
};

export default BlueOverlay;
