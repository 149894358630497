const PharmaceuticalCorporationsData = {
    header: {
        headerSolutionTitle: "Pharmaceutical Corporations",
        headerDescription:
            "Provide the right tools to your physicians to help them improve their practice.",
    },
    solutions: {
        topic: {
            benefit1: {
                headeline1:
                    "Help your physicians address care gaps and improve their efficiencies through EMR analytics solutions.",
            },
            info1: {
                headerText1: `Practice efficiencies, patient care gaps, and guideline adherence can all be achieved through EMR data analysis. Provide our solutions to your physicians to help you become a true partner to them.`,
                headerText2: `Values pharmaceutical corporations can expect`,
                benefits: [
                    {
                        text:
                            "Help physicians address care gaps and promote guideline-based practice",
                    },
                    {
                        text:
                            "Improve physician practice efficiencies, allowing them more resources to address more patient care gaps",
                    },
                    {
                        text:
                            "Become a physician-centric partner by providing value and building relationships",
                    },
                    {
                        text: "Give back to the community by improving care",
                    },
                ],
            },
            clinicsAd: {
                headerText:
                    "Join these partners using JustPractice to transform their business",
                logoList: [
                    {
                        logoSrc: "divisions_pic.png",
                        name: "Divisions of Family Practice",
                    },
                    {
                        logoSrc: "edocs.png",
                        name: "Electronic Medical Record",
                    },
                    {
                        logoSrc: "logo-camrosepcn.png",
                        name: "Camrose Primary Care Network",
                    },
                    {
                        logoSrc: "logo-gpsc.png",
                        name: "General Practice Services Committee",
                    },
                ],
            },
            info2: {
                infoCategory: `Preventative medicine starts with identifying the
                right patients with care gaps`,
                infoTitle: `A solution-centric mindset to ensure we achieve your objectives, rather than focussing on our product.`,
                infoText: `Whether it's a complicated EMR, a niche EMR, disorganized patient data, or non-standard clinic workflows, we focus on addressing the needs and solving the problems of physicians and pharmaceutical partners rather than being focussed around our product.`,
                subDescriptionTitle1: `Turnkey solution`,
                subDescriptionText1: `Ranging from the medical aspect of interpreting and reverse-engineering guidelines, to the business development of identifying the right guideline society as partners to gain credibility and scale, we package all necessary components to ensure a plug and play solution for our pharmaceutical partners.`,
                subDescriptionTitle2: `Minimize risks with pilots`,
                subDescriptionText2: `Try out our solution with a cost-friendly pilot project that is easy to approve both in terms of budget and compliancy. Scale only when the metrics indicate business success.`,
            },
            quote: {
                quoteText: `"Great service, that made it much easier for me to do
                the review. In my mind, this is the only way to to go.
                Thanks for all your help."`,
                quoter: "Dr. William Lee",
            },
            info3: {
                infoCategory: "Know Who They Are",
                infoTitle: `Clinical improvements you can expect for your physicians`,
                infoText: `Panorama helps identify patients with medication, lab, investigation, and screening care gaps. Panorama also helps with making diagnoses based on available patient information and published guideline diagnostic criteria.`,
                subDescriptionTitle1:
                    "How does Panorama optimize patient care?",
                subDescriptionText1: `Guidelines published by national societies allow physicians to practice evidence-based medicine. However, the guidelines are constantly being updated as new evidence emerges. Furthermore, guidelines can be difficult to translate into practice as the information is extremely dense. Panorama comes into play by reverse-engineering guideline recommendations into parameters, and identifying patients from the EMR that are not being cared for according to these recommendations for a second look.`,
                subDescriptionTitle2: "What are some examples?",
                subDescriptionText2: `According to Diabetes Canada's guidelines, patients with A1c above target by greater than 1.5% and clinical cardiovascular disease symptoms should start metformin and a second antihyperglycemic agent that is shown to be cardioprotective. Based on this recommendation, Panorama can identify patients with cardiovascular symptoms with A1c out of target that are not on a second antihyperglycemic agent for the physician to have a second look and consider initiating that second agent.`,
            },
            heroHeader: {
                headerText:
                    "Join a nationwide network of physicians already using JustPractice to manage their patients.",
                buttonText: "GET STARTED FOR FREE",
            },
        },
    },
};

export default PharmaceuticalCorporationsData;
