import React, { useState } from "react";
import { Carousel } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as filledCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import "antd/dist/antd.css";
import "../../styles/Slider.scss";

const Slider = props => {
    let carousel = React.createRef();
    const [sliderItem, setSliderItem] = useState(0);
    let { arr } = props;

    let changeSlides = newSlideNum => {
        carousel.current.goTo(newSlideNum);
    };
    return (
        <div className="slider">
            <ul className="list">
                {arr.map((item, key) => {
                    let { avatar, name } = item;
                    return (
                        <li
                            className={sliderItem === key ? "active" : ""}
                            key={key}
                            onClick={() => {
                                setSliderItem(key);
                                changeSlides(key);
                            }}
                        >
                            <img
                                className="slider-img"
                                src={avatar && `${avatar}`}
                                alt={name}
                                title={name}
                                height="50px"
                            />
                            {sliderItem === key ? (
                                <FontAwesomeIcon
                                    className="slider-circle"
                                    icon={filledCircle}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="slider-circle"
                                    icon={faCircle}
                                />
                            )}
                        </li>
                    );
                })}
            </ul>
            <Carousel
                className="slider-carousel"
                ref={carousel}
                dots={true}
                autoplay={true}
                autoplaySpeed={6000}
                afterChange={slide => {
                    setSliderItem(slide);
                }}
            >
                {arr.map((item, key) => {
                    let { text, name, location, background, avatar } = item;
                    return (
                        <div className="slider-content" key={key}>
                            <div
                                className="slider-text-wrapper"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    background: `linear-gradient(#666666, #d8d8d8), url(${background}) no-repeat center`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "0% 20%",
                                    backgroundBlendMode: "multiply"
                                }}
                            >
                                <div className="slider-text">
                                    <h3>"{text}"</h3>
                                    <div className="meta">
                                        <h5>{name}</h5>
                                        <h6>{location}</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="mobile-slider-wrapper">
                                <div
                                    style={{
                                        height: "300px",
                                        width: "95%",
                                        background: `url(${background}) no-repeat center`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "0% 20%"
                                    }}
                                ></div>

                                <div className="mobile-slider-text">
                                    <h3>"{text}"</h3>
                                    <div className="meta">
                                        <div className="meta-description">
                                            <h5>{name}</h5>
                                            <h6>{location}</h6>
                                        </div>
                                        <div className="meta-logo">
                                            <img
                                                className="logo"
                                                src={avatar}
                                                alt={avatar}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default Slider;
