import React from "react";
import "../styles/Home.scss";
import { HomeData } from "../_data";
import { StyledBtn, Slider, BlueOverlay } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
    return (
        <div className="home">
            <div className="hero-header-1">
                <BlueOverlay src="header-1.jpg">
                    <div className="hero-header-1-wrapper">
                        <div className="hero-header-1-content">
                            <h1 className="header-title">
                                {HomeData.header1.headerTitle}
                            </h1>
                            <h5 className="header-description">
                                {HomeData.header1.headerDescription}
                            </h5>
                            <div className="button-wrapper">
                                <a href="/contact_us">
                                    <StyledBtn shadow={true}>
                                        {HomeData.header1.buttonText}
                                    </StyledBtn>
                                </a>
                            </div>
                            <div className="extra-info">
                                <span>{HomeData.header1.extraInfoSpan}</span>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://panorama.justpractice.ca/"
                                >
                                    {HomeData.header1.extraInfoA}
                                </a>
                            </div>
                        </div>
                    </div>
                </BlueOverlay>
            </div>
            <div className="panorama">
                <h1>
                    <img
                        className="logo"
                        src="/Icon-Filled.svg"
                        width="40px"
                        alt="JustPractice-Logo"
                    />
                    <span className="first-word">Panorama</span> by JustPractice
                </h1>
                <h3>{HomeData.panorama.headerText}</h3>
                <a
                    href="https://demo.justpractice.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pano-link"
                >
                    <img src="/Pano-dash.png" width="100%" alt="Panorama" />
                </a>
            </div>
            <div className="advantages">
                {HomeData.advantages.map((item, key) => {
                    return (
                        <div className="advantage" key={key}>
                            <FontAwesomeIcon
                                size="2x"
                                icon={item.src}
                                color="black"
                            />
                            <h3>{item.title}</h3>
                            <span>{item.content}</span>
                        </div>
                    );
                })}
            </div>
            {/* <div className="supporters">
                <h1 className="supporters-header">
                    {HomeData.supporters.headerText}
                </h1>
                <ul className="supporters-list">
                    {HomeData.supporters.supportersList.map((item, key) => {
                        return (
                            <li className="supporter" key={key}>
                                <img
                                    src={item.logoSrc}
                                    alt={item.name}
                                    title={item.name}
                                    height="35px"
                                />
                            </li>
                        );
                    })}
                </ul>
            </div> */}
            <div className="hero-header-2">
                <BlueOverlay src="michael_2.jpg" center flip>
                    <div className="hero-header-2-content">
                        <img src="/Icon-Filled.svg" alt="logo" />
                        <h1>{HomeData.header2.headerText}</h1>
                        <div className="button-wrapper">
                            <a href="/contact_us">
                                <StyledBtn shadow={true}>
                                    {HomeData.header2.buttonText}
                                </StyledBtn>
                            </a>
                        </div>
                    </div>
                </BlueOverlay>
            </div>
            <div className="testimonials">
                <h1>{HomeData.testimonials.headerText}</h1>
                <Slider arr={HomeData.testimonials.sliderArr} />
            </div>
            <div className="stories">
                <h1 className="more-stories">{HomeData.stories.headerText}</h1>
                <div className="stories-content">
                    {HomeData.stories.storiesContent.map((item, key) => {
                        return (
                            <div className="story" key={key}>
                                <div
                                    className="story-img"
                                    style={{
                                        backgroundImage: `url(${item.src})`,
                                    }}
                                ></div>
                                <label className="category">
                                    {item.category}
                                </label>
                                <h3 className="title">{item.title}</h3>
                                <h5 className="description">
                                    {item.description}
                                </h5>
                                <a
                                    href={`/case_studies/${item.id}`}
                                    className="learn-more"
                                >
                                    LEARN MORE
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Home;
