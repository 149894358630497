import React from "react";
import "../styles/UserConfidentiality.scss";

const UserConfidentiality = () => {
    return (
        <div className="user-confidentiality">
            <div className="header-wrapper">
                <div className="header">
                    <h1 className="header-title">
                        Commitments to User Confidentiality
                    </h1>
                    <h3 className="header-description">
                        JustPractice Technologies Inc. Commitments to
                        Confidentiality and Security
                    </h3>
                </div>
            </div>
            <div className="content">
                <h5 className="text">
                    JustPractice Technologies Inc (“JustPractice”) believes in
                    providing the best value for our customers and users.
                    JustPractice learns from our experiences and strives to
                    improve our services with each encounter. As part of our
                    mission, JustPractice is given the privilege of being
                    provided with confidential information from our customers,
                    users, and corporate partners. JustPractice has set up
                    processes, internal guidelines and gone through routine
                    audits to ensure the protection of confidential information.
                </h5>
                <h2 className="info-title">
                    What is confidential Information?
                </h2>
                <h5 className="text">
                    JustPractice considers confidential information as any
                    information which is confidential in nature or that is
                    treated as being confidential by our partners. Whether such
                    information is or has been conveyed verbally or in written
                    or other tangible forms, and whether such information is
                    acquired directly or indirectly.
                </h5>
                <h2 className="info-title">
                    How does JustPractice handle confidential information?
                </h2>
                <h5 className="text">
                    Core values to handling confidential information
                    appropriately:
                </h5>
                <h3 className="title">
                    Core Value #1: The Right Way of Handling Patient Information
                </h3>
                <h5 className="text">
                    At JustPractice, we understand the importance of patient
                    information and we understand social responsibility that
                    comes with the handling of this information. JustPractice is
                    committed to following any local privacy laws, the terms of
                    service, internal policies with our customers and partners.
                </h5>
                <h3 className="title">
                    Core Value #2: The Right Way of Handling Our Partners'
                    Confidential Information
                </h3>
                <h5 className="text">
                    JustPractice will never share the following unless
                    permitted:
                </h5>
                <ol>
                    <li className="list">
                        Information that can be used to identify the physicians
                        or practices
                    </li>
                    <li className="list">
                        Confidential information concerning our partners
                    </li>
                    <li className="list">
                        Proprietary work developed in conjunction with our
                        business partners
                    </li>
                </ol>
                <h3 className="title">
                    Core Value #3: Appropriate handling of information when
                    multiple parties are involved with a project
                </h3>
                <h5 className="text">
                    JustPractice works with numerous partners to set up programs
                    that could ultimately benefit physicians’ practices. During
                    the course of the business, we are committed to ensuring
                    relevant information will only be shared with parties
                    involved with written consent. All information will be
                    handled according to JustPractice Policies, relevant legal
                    agreements and internal policies set by our partners
                </h5>
                <h3 className="title">Core Value #4: Objective analytics</h3>
                <h5 className="text">
                    Part of JustPractice’s work is to utilize published
                    guidelines to help doctors identify patients that may
                    benefit from a second look. We are committed to alway using
                    the most up to date guidelines and will always take an
                    objective stance towards the results of the analytics. We
                    want what’s best for the physician and ultimately what’s
                    best for the patients.
                </h5>
            </div>
        </div>
    );
};

export default UserConfidentiality;
