import React from "react";
import { PharmaceuticalCorporationsData } from "../_data";
import Solutions from "./Solutions";
import "../styles/PharmaceuticalCorporations.scss";

const PharmaceuticalCorporations = () => {
    return <Solutions data={PharmaceuticalCorporationsData} />;
};

export default PharmaceuticalCorporations;
