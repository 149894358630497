import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { StyledBtn, InvisCaptcha } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { BlueOverlay } from "../components";
import "../styles/MedicalAssociations.scss";
import MedicalAssociationsData from "../_data/MedicalAssociationsData";

const MedicalAssociations = () => {
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState("");
    const [notValidEmail, setNotValidEmail] = useState(false);

    const recaptchaRef = React.createRef();

    const handleSubmit = () => {
        setNotValidEmail(false);
        if (EmailValidator.validate(email.trim())) {
            recaptchaRef.current.execute();
        } else {
            setNotValidEmail(true);
        }
    };

    const sendEmail = (res) => {
        let requestOptions = {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email.trim(),
                captcha: res,
            }),
        };

        fetch("https://justpractice.ca/api/email", requestOptions);

        setEmailSent(true);
    };
    return (
        <div className="physician-organizations">
            <div className="physician-organizations-header-wrapper">
                <BlueOverlay src="/physician-organizations.png" flip center>
                    <div className="physician-organizations-header">
                        <h1 className="header-title">
                            {MedicalAssociationsData.headerTitle}
                        </h1>
                        <h5 className="header-description ">
                            {MedicalAssociationsData.headerDescription}
                        </h5>
                    </div>
                </BlueOverlay>
            </div>
            <div className="content">
                <div className="physician-organizations-benefit">
                    <div className="text-wrapper benefit-1">
                        <div className="headline-1-wrapper">
                            <h1 className="headline-1">
                                {MedicalAssociationsData.headline1}
                            </h1>
                            <div className="email-form">
                                {!emailSent ? (
                                    <div className="email-form-content">
                                        <InvisCaptcha
                                            recaptchaRef={recaptchaRef}
                                            action={sendEmail}
                                        />
                                        <div className="input-wrapper">
                                            <input
                                                className="input-box email-input"
                                                type="email"
                                                placeholder="Email address"
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                            />
                                            {notValidEmail ? (
                                                <p className="text error">
                                                    Not a valid email address.
                                                    Please make sure to put in
                                                    the correct email!
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="button-wrapper">
                                            <StyledBtn
                                                className="bigBtn"
                                                action={handleSubmit}
                                            >
                                                TRY IT OUT
                                            </StyledBtn>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="email-confirmation">
                                        <span>
                                            Thank you for your interest, we will
                                            be with you shortly!
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="info-1">
                            <h5 className="text">
                                {MedicalAssociationsData.info1.headerText}
                            </h5>
                            <h3>Some benefits for the value proposition</h3>
                            <ul className="benefits">
                                {MedicalAssociationsData.info1.benefits.map(
                                    (data, key) => (
                                        <li>
                                            <FontAwesomeIcon
                                                size="2x"
                                                icon={faCheckCircle}
                                                color="#2E319C"
                                            />
                                            <span>{data.text}</span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="clinics-ad">
                        <h3>{MedicalAssociationsData.clinicsAd.headerText}</h3>
                        <ul className="logo-list">
                            {MedicalAssociationsData.clinicsAd.logoList.map(
                                (item, key) => {
                                    return (
                                        <li className="supporter" key={key}>
                                            <img
                                                src={item.logoSrc}
                                                alt={item.name}
                                                title={item.name}
                                                height="35px"
                                            />
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </div>
                    <div className="physician-oragnizations-benefit benefit-2">
                        <div className="text-wrapper">
                            <div className="info-2">
                                <h3 className="info-title">
                                    {MedicalAssociationsData.info2.infoTitle}
                                </h3>
                                <h3 className="sub-description-title">
                                    {
                                        MedicalAssociationsData.info2
                                            .subDescriptionTitle1
                                    }
                                </h3>
                                <h5 className="text">
                                    {
                                        MedicalAssociationsData.info2
                                            .subDescriptionText1
                                    }
                                </h5>
                                <h3 className="sub-description-title">
                                    {
                                        MedicalAssociationsData.info2
                                            .subDescriptionTitle2
                                    }
                                </h3>
                                <h5 className="text">
                                    {
                                        MedicalAssociationsData.info2
                                            .subDescriptionText2
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="quote">
                        <div className="text-wrapper">
                            <h1>{MedicalAssociationsData.quote.headerText}</h1>
                            <h3>{MedicalAssociationsData.quote.quoter}</h3>
                        </div>
                    </div>
                    <div className="diabetes-benefit benefit-3 background-grey">
                        <div className="text-wrapper">
                            <div className="info-3">
                                <h3 className="info-title">
                                    {MedicalAssociationsData.info3.infoTitle}
                                </h3>
                                <h3 className="sub-description-title">
                                    {
                                        MedicalAssociationsData.info3
                                            .subDescriptionTitle1
                                    }
                                </h3>
                                <h5 className="text">
                                    {
                                        MedicalAssociationsData.info3
                                            .subDescriptionText1
                                    }
                                </h5>
                                <h3 className="sub-description-title">
                                    {
                                        MedicalAssociationsData.info3
                                            .subDescriptionTitle2
                                    }
                                </h3>
                                <h5 className="text">
                                    {
                                        MedicalAssociationsData.info3
                                            .subDescriptionText2
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hero-header">
                <BlueOverlay src="michael_1.jpg" center flip>
                    <div className="hero-header-content">
                        <img src="/Icon-Filled.svg" alt="logo" />
                        <h1>
                            Join a nationwide network of physicians already
                            using JustPractice to manage their patients.
                        </h1>
                        <div className="button-wrapper">
                            <a href="/contact_us">
                                <StyledBtn shadow={true}>
                                    GET STARTED FOR FREE
                                </StyledBtn>
                            </a>
                        </div>
                    </div>
                </BlueOverlay>
            </div>
        </div>
    );
};

export default MedicalAssociations;
