const PatientMedicalHomeTeamsData = {
    headerTitle: "Patient Medical Home Teams",
    headerDescription: `Optimize the efficiency of EMR administration for your physicians through automation solutions.`,
    headline1: `Optimize efficiency, reduce cost, and reduce workload associated with EMR data updating and report generation.`,
    info1: {
        headerText: `Whether your team is looking to provide an accurate report on the state of your patient population
        to the ministry, or gather data from the EMR for strategic planning, the administrative tasks of
        updating the EMR patient profiles and generating reports with queries can be quite tedious, though necessary.
        Executing these tasks manually can not only be time consuming, but it can also become costly.`,
        benefitTitle: `What patient medical home teams can expect`,
        benefits: [
            {
                text:
                    "Automated patient profile updating and report generation from the EMR",
            },
            {
                text: "updated patient profiles within the EMR",
            },
            {
                text: "Accurate and up-to-date patient population reports",
            },
            {
                text:
                    "Spend less time with EMR administration and more time on strategic planning",
            },
            {
                text:
                    "Single vendor solution. No more dealing with one EMR vendor at a time",
            },
        ],
    },
    clinicsAd: {
        headerText: `Join these partners using JustPractice to transform
        their business`,
        logoList: [
            {
                logoSrc: "divisions_pic.png",
                name: "Divisions of Family Practice",
            },
            {
                logoSrc: "edocs.png",
                name: "Electronic Medical Record",
            },
            {
                logoSrc: "logo-camrosepcn.png",
                name: "Camrose Primary Care Network",
            },
            {
                logoSrc: "logo-gpsc.png",
                name: "General Practice Services Committee",
            },
        ],
    },
    info2: {
        infoTitle: `Minimize manual labour on EMR-related administrative tasks`,
        subDescriptionTitle1: `Accurate reporting`,
        subDescriptionText1: `As a team practicing by the patient medical
        home model, proper EMR optimization and reporting is critical. Accurate reports could be used
        for a number of purposes:`,
        list1: [
            `Mandatory reporting to funders`,
            `Identifying current quality indicator progress`,
            `Quality improvement goals`,
            `Strategic planning such as hiring or resource allocation`,
        ],
        subDescriptionText12: `
        The process of arriving at the applicable reports can be expensive and tedious especially if done
        manually. This is why we aim to provide an automated solution to solve this problem in a cost- effective
        approach.`,
        subDescriptionTitle2: `Expect a tailored solution not a manual`,
        subDescriptionText2: `Every patient medical home team, practitioner, and EMR are different in their own ways. The
        difference can be in practice workflow, EMR structure, operational workflow, or administrative
        workflow. All of these differences can affect how EMR administration and reporting are
        conducted. Therefore, the automation and optimization of these tasks must be carefully tailored
        to the unique needs of our clients to ensure it properly solves a problem, rather than the traditional packaged software selling
        that includes a user's manual.`,
    },
    quote: {
        headerText: `"The JustPractice team made the GPSC Paneling process seamless and enjoyable. I learned a lot from the exercise and cannot recommend JustPractice highly enough"`,
        quoter: `Dr. Eric Cadesky`,
    },
    info3: {
        infoTitle: `What kind of administrative tasks can we automate?`,
        subDescriptionTitle1: `EMR data updating`,
        subDescriptionText1: `In order to leverage the information and reports
        derived from the EMR, the data within patient profiles must by updated. When it's outdated, the
        report naturally becomes inaccurate. Some examples of update:`,
        list1: [
            `Patient activity status`,
            `Patient rostering`,
            `Disease registries`,
            `Structure free text physician notes into appropriate fields`,
        ],
        subDescriptionTitle2: `EMR reporting`,
        subDescriptionText2: `There are many ways an organization can generate
        reports from their EMR (or EMRs). Depending on the depth of the report, the number of EMRs
        to generate reports from, or the types of EMRs, the approach can range from a simple query to a
        complicated operating procedure that is expensive and difficult to coordinate. With highly
        detailed reports, the EMR system may not even have the reporting capability. This results in the
        user having to manually manipulate the data just to generate a single report. We aim to provide a
        customized automation software that automates the process of EMR reporting and data
        processing (as needed) that is customized to how the organization operates and the kind of EMR
        they operate on. In addition, physician notes often contain valuable information that are not
        properly labelled within the EMR. Panorama has the capability of going through free text notes
        and structure them into appropriate fields without the EMR for better report generation. With a
        standardization of EMR data, communication between multidisciplinary teams will be less
        ambiguous and more structured.`,
    },
};

export default PatientMedicalHomeTeamsData;
