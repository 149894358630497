import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { StyledBtn, BlueOverlay, InvisCaptcha } from "../components";
import "../styles/Contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faLinkedin,
    faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

const Contact = (props) => {
    const [messageSent, setMessageSent] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [notValidName, setNotValidName] = useState(false);
    const [notValidEmail, setNotValidEmail] = useState(false);
    const [notValidMessage, setNotValidMessage] = useState(false);

    const recaptchaRef = React.createRef();

    const sendMessage = (res) => {
        let requestOptions = {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name.trim(),
                email: email.trim(),
                message: message.trim(),
                captcha: res,
            }),
        };

        fetch("https://justpractice.ca/api/email", requestOptions);

        setMessageSent(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setNotValidName(false);
        setNotValidEmail(false);
        setNotValidMessage(false);

        // this is because the validations are not being set fast enough in the if statements
        let valid = true;

        if (name.trim() === "") {
            setNotValidName(true);
            valid = false;
        }

        if (!EmailValidator.validate(email.trim())) {
            setNotValidEmail(true);
            valid = false;
        }

        if (message.trim() === "") {
            setNotValidMessage(true);
            valid = false;
        }

        if (valid) {
            recaptchaRef.current.execute();
        }
    };

    return (
        <div className="contact">
            {/* Contact plan
            - https://blog.hubspot.com/hs-fs/hubfs/choice-screening-contact-us-page-update.png?width=2250&name=choice-screening-contact-us-page-update.png
        */}
            <div className="header-wrapper">
                <BlueOverlay src="contact.jpg" center flip>
                    <div className="header">
                        <h1 className="header-title">Talk to a Human</h1>
                        <h3 className="header-description">
                            Feel Free To Ask Anything!
                        </h3>
                    </div>
                </BlueOverlay>
            </div>
            <div className="content">
                <div className="description">
                    <div className="talk-to-us">
                        <h3 className="title">Talk to Us</h3>
                        <h5 className="text">
                            Ever get frustrated trying to get in contact with
                            your EMR provider? Hit with long phone menus and
                            unresponded emails when trying to see why the server
                            is down? Here at JustPractice we aim to provide the
                            best customer experience to all of our clients. Have
                            any questions regarding patient data security, the
                            analytics process, or just want to chat about how to
                            improve patient care? Call us!
                        </h5>
                    </div>
                    <h3 className="title">
                        JustPractice aims to provide the best customer
                        experience with every contact!
                    </h3>
                    <h5 className="text">
                        We are here to beat the status quo and we believe the
                        physicians deserve better customer service than they are
                        getting now. At the end of the day, physicians’ time
                        shouldn’t be spent on dealing with a robot on the other
                        end of a call.
                    </h5>
                    <h5 className="text">
                        As a steward of patients’ data, we understand the
                        importance of talking to a specialist before committing.
                    </h5>
                    <h5 className="text">
                        Have any questions about data security? Don’t hesitate
                        to hop on a call with our specialists. We will explain
                        how we protect your patient data. From legal compliance
                        to data securities, we are here to answer all of your
                        questions.
                    </h5>
                    <h5 className="text">
                        Interested in learning more about Panorama’s
                        capabilities and how it could provide a seamless way of
                        improving your practice? Our account managers will go
                        through the step by step process of how our algorithms
                        look for patients that may benefit from a visit.
                    </h5>
                    <br />
                    <h3 className="title">Complicancy related inquiries</h3>
                    <h5 className="text">
                        Should you have any compliancy related questions or
                        concerns, please do not hesitate to reach out to us. We
                        take compliancy and security very seriously, and as a
                        result, we engineer both our architecture and workflow
                        to be compliant in wherever we operate in. Whether it be
                        PIPA, HIPAA, or PIPEDA, we ensure our products and
                        services remain secure and compliant at all times.
                        Should you not hear a response from us after submitting
                        a form, please try reaaching out to our compliancy
                        officers directly at danny.lo@justpractice.ca or
                        jorden.lo@justpractice.ca, and we will address your
                        concerns immediately.
                    </h5>
                </div>
                <div className="contact-info">
                    <h1 className="title">Corporate Office</h1>
                    <h3 className="text">10013 River Dr. #2030</h3>
                    <h3 className="text">Richmond, BC V6X 1Z2</h3>
                    <br />
                    <h1 className="title">Social Media</h1>
                    <div className="media">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/justpracticemed/"
                        >
                            <FontAwesomeIcon
                                size="2x"
                                icon={faInstagram}
                                color="#191A2E"
                            />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/justpracticetech"
                        >
                            <FontAwesomeIcon
                                size="2x"
                                icon={faFacebookSquare}
                                color="#191A2E"
                            />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://ca.linkedin.com/company/jmj-medical-inc"
                        >
                            <FontAwesomeIcon
                                size="2x"
                                icon={faLinkedin}
                                color="#191A2E"
                            />
                        </a>
                    </div>
                </div>
            </div>
            {!messageSent ? (
                <div className="contact-form-wrapper">
                    <h1 className="title">Contact Us Today</h1>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <InvisCaptcha
                            recaptchaRef={recaptchaRef}
                            action={sendMessage}
                        />

                        <label className="text">Name</label>
                        <input
                            type="text"
                            className="input-box"
                            onChange={(e) => setName(e.target.value)}
                        />
                        {notValidName ? (
                            <p className="text error">
                                Not a valid name. Please Make sure to put in the
                                correct name!
                            </p>
                        ) : null}
                        <label className="text">Email</label>
                        <input
                            type="text"
                            className="input-box"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {notValidEmail ? (
                            <p className="text error">
                                Not a valid email. Please make sure to put in
                                the correct email!
                            </p>
                        ) : null}
                        <label className="text">Message</label>
                        <textarea
                            className="textarea-box"
                            onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        {notValidMessage ? (
                            <p className="text error">
                                Don't forget to put a message!
                            </p>
                        ) : null}
                        <StyledBtn type="submit">Talk to Us</StyledBtn>
                    </form>
                </div>
            ) : (
                <div className="email-confirmation">
                    <h3>
                        Thanks for your message, we will get back to you
                        shortly!
                    </h3>
                </div>
            )}
        </div>
    );
};

export default Contact;
