const PhysicianOrganizationsData = {
    headerTitle: "Physicians Organizations",
    headerDescription: `Providing cutting edge analytics to help the
    physicians you support the tools they need to do
    what’s right for their patients.`,
    headline1: `Help your physicians adhere and practice
    according to your guidelines.`,
    info1: {
        headerText: `More often than not, physicians are too busy to
        constantly check to ensure the data being
        entered into their EMR is correct. This results
        in incorrectly structured EMR data, which could
        obscure the state of patient panel for the
        office. The longer the physicians have practiced
        for, the more obscure the panel state becomes.
        It becomes increasingly difficult to accurately
        assess the patient population the messier the
        EMR data becomes. This causes an obvious
        difficulty in team collaboration, clinic
        demand/supply, and disease composition, which
        directly hinders operations. Furthermore, by not
        knowing the disease states of the patients, it
        becomes difficult to effectively allocate
        disease specific resources, or even to identify
        and address disease specific care gaps of
        individual patients.`,
        benefitTitle: `Some benefits for the value proposition`,
        benefits: [
            {
                text: "Accurate clinic panel assessment",
            },
            {
                text: "Help physicians address patient care gaps",
            },
            {
                text: "Improve clinical operations",
            },
            {
                text: "Increase availability and patient access",
            },
            {
                text: "Data-driven resource allocation",
            },
        ],
    },
    clinicsAd: {
        headerText: `Join these partners using JustPractice to transform
        their business`,
        logoList: [
            {
                logoSrc: "divisions_pic.png",
                name: "Divisions of Family Practice",
            },
            {
                logoSrc: "edocs.png",
                name: "Electronic Medical Record",
            },
            {
                logoSrc: "logo-camrosepcn.png",
                name: "Camrose Primary Care Network",
            },
            {
                logoSrc: "logo-gpsc.png",
                name: "General Practice Services Committee",
            },
        ],
    },
    info2: {
        infoTitle: `Providing an accurate and robust panel
        assessment`,
        subDescriptionTitle1: `Patient status and MRP`,
        subDescriptionText1: `Through customizable parameters that define
        the status and MRP of the patients, Panorama
        can automate the extraction and analysis
        process to accurately and efficiently
        identify patients that require their active
        status and MRP to be updated. This has a
        direct impact on how many truly active
        patients a clinic has, as well as who is
        responsible for the care continuity of the
        patients.`,
        subDescriptionTitle2: `Disease Registry`,
        subDescriptionText2: `As busy practitioners, it is very easy for
        physicians to forget to enter an official
        diagnosis into patient records even after an
        official diagnosis is made. This can
        potentially lead to pseudo-undiagnosed
        patients or wasting time to have to go
        through the process of diagnosing the
        patient again at a later time. A less
        obvious impact is that it is difficult to
        tell right away which patients are living
        with a chronic disease. As a result, it may
        be difficult to identify chronic disease
        patients with clinical care gaps to address.
        Panorama has the capability to
        algorithmically identify patients that
        should have had a diagnosis based on
        customizable parameters, and update the
        disease registry to ensure the most accurate
        panel.`,
    },
    quote: {
        headerText: `"A real time-saver. Helped clarify the
        intricacies of panel management and they did all
        the heavy slogging on our behalf to get things
        in order. Whiz kids!"`,
        quoter: `Dr. Stephen Malthouse - Denman Island Clinics`,
    },
    info3: {
        infoTitle: `Improving patient outcomes, clinical
        operations, and resource supports`,
        subDescriptionTitle1: `If you can’t measure it you can’t improve it`,
        subDescriptionText1: `The most valuable resource for any quality
        improvement efforts come from patient and
        clinical data. Stakeholders responsible for
        specific QI efforts should have a clear
        picture of the data to obtain the objective
        truth, rather than relying on something less
        robust. Panorama provides data and evidence
        to different stakeholders to allow a simple
        QI decision making process. Whether it be
        identifying patients with clinical care gaps
        for physicians, patient accessibility and
        physician availability problems for
        managers, or resources required to improve
        clinics for physician support organizations,
        Panorama can deliver.`,
        subDescriptionTitle2: `If you can’t make sense of it you can’t
        measure it`,
        subDescriptionText2: `Data can be intimidating. Whether the
        stakeholder is a physician, clinical
        manager, or a key decision maker in an
        organization, no one has time to interpret
        messy, complicated data. Panorama provides
        completely processed data in the most
        intuitive way possible to distill the
        information down to only what one needs to
        know to cut out the noise.`,
    },
};

export default PhysicianOrganizationsData;
