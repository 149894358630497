import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { StyledBtn, BlueOverlay, InvisCaptcha } from "../../components";
import "../../styles/SolutionsTemplate.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const SolutionsTemplate = (props) => {
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState("");
    const [notValidEmail, setNotValidEmail] = useState(false);

    const recaptchaRef = React.createRef();

    const handleSubmit = () => {
        setNotValidEmail(false);
        if (EmailValidator.validate(email.trim())) {
            recaptchaRef.current.execute();
        } else {
            setNotValidEmail(true);
        }
    };

    const sendEmail = (res) => {
        let requestOptions = {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email.trim(),
                captcha: res,
            }),
        };

        fetch("https://justpractice.ca/api/email", requestOptions);

        setEmailSent(true);
    };
    console.log(props.data);

    return (
        <div className="solutions-template">
            <div className="solutions-template-benefit">
                <div className="text-wrapper benefit-1">
                    <div className="headline-1-wrapper">
                        <h1 className="headline-1">
                            {props.data.solutions.topic.benefit1.headeline1}
                        </h1>
                        <div className="email-form">
                            {!emailSent ? (
                                <div className="email-form-content">
                                    <InvisCaptcha
                                        recaptchaRef={recaptchaRef}
                                        action={sendEmail}
                                    />
                                    <div className="input-wrapper">
                                        <input
                                            className="input-box email-input"
                                            type="email"
                                            placeholder="Email address"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                        {notValidEmail ? (
                                            <p className="text error">
                                                Not a valid email address.
                                                Please make sure to put in the
                                                correct email!
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="button-wrapper">
                                        <StyledBtn
                                            className="bigBtn"
                                            action={handleSubmit}
                                        >
                                            TRY IT OUT
                                        </StyledBtn>
                                    </div>
                                </div>
                            ) : (
                                <div className="email-confirmation">
                                    <span>
                                        Thank you for your interest, we will be
                                        with you shortly!
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="info-1">
                        <h5 className="text">
                            {props.data.solutions.topic.info1.headerText1}
                        </h5>
                        <h3>{props.data.solutions.topic.info1.headerText2}</h3>
                        <ul className="benefits">
                            {props.data.solutions.topic.info1.benefits.map(
                                (data, key) => (
                                    <li key={key}>
                                        <FontAwesomeIcon
                                            size="2x"
                                            icon={faCheckCircle}
                                            color="#2E319C"
                                        />
                                        <span>{data.text}</span>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="clinics-ad">
                <h3>{props.data.solutions.topic.clinicsAd.headerText}</h3>
                <ul className="logo-list">
                    {props.data.solutions.topic.clinicsAd.logoList.map(
                        (item, key) => {
                            return (
                                <li className="supporter" key={key}>
                                    <img
                                        src={item.logoSrc}
                                        alt={item.name}
                                        title={item.name}
                                        height="35px"
                                    />
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
            <div className="solutions-template-benefit benefit-2 background-grey">
                <div className="text-wrapper">
                    <div className="info-2">
                        <h5 className="info-category">
                            {props.data.solutions.topic.info2.infoCategory}
                        </h5>
                        <h1 className="info-title">
                            {props.data.solutions.topic.info2.infoTitle}
                        </h1>
                        <h5 className="text">
                            {props.data.solutions.topic.info2.infoText}
                        </h5>
                        <h4 className="sub-description-title">
                            {
                                props.data.solutions.topic.info2
                                    .subDescriptionTitle1
                            }
                        </h4>
                        <h5 className="text">
                            {
                                props.data.solutions.topic.info2
                                    .subDescriptionText1
                            }
                        </h5>
                        <h4 className="sub-description-title">
                            {
                                props.data.solutions.topic.info2
                                    .subDescriptionTitle2
                            }
                        </h4>
                        <h5 className="text">
                            {
                                props.data.solutions.topic.info2
                                    .subDescriptionText2
                            }
                        </h5>
                    </div>
                    <img src="" alt="" />
                </div>
            </div>
            <div className="quote">
                <div className="text-wrapper">
                    <h1>{props.data.solutions.topic.quote.quoteText}</h1>
                    <h3>{props.data.solutions.topic.quote.quoter}</h3>
                </div>
            </div>
            <div className="solutions-template-benefit benefit-3">
                <div className="text-wrapper">
                    <div className="info-3">
                        <h5 className="info-category">
                            {props.data.solutions.topic.info3.infoCategory}
                        </h5>
                        <h1 className="info-title">
                            {props.data.solutions.topic.info3.infoTitle}
                        </h1>
                        <h5 className="text">
                            {props.data.solutions.topic.info3.infoText}
                        </h5>

                        <h4 className="sub-description-title">
                            {
                                props.data.solutions.topic.info3
                                    .subDescriptionTitle1
                            }
                        </h4>
                        <h5 className="text">
                            {
                                props.data.solutions.topic.info3
                                    .subDescriptionText1
                            }
                        </h5>

                        <h4 className="sub-description-title">
                            {
                                props.data.solutions.topic.info3
                                    .subDescriptionTitle2
                            }
                        </h4>
                        <h5 className="text">
                            {
                                props.data.solutions.topic.info3
                                    .subDescriptionText2
                            }
                        </h5>
                    </div>
                </div>
            </div>
            <div className="hero-header">
                <BlueOverlay src="michael_1.jpg" center flip>
                    <div className="hero-header-content">
                        <img src="/Icon-Filled.svg" alt="logo" />
                        <h1>
                            {props.data.solutions.topic.heroHeader.headerText}
                        </h1>
                        <div className="button-wrapper">
                            <a href="/contact_us">
                                <StyledBtn shadow={true}>
                                    {
                                        props.data.solutions.topic.heroHeader
                                            .buttonText
                                    }
                                </StyledBtn>
                            </a>
                        </div>
                    </div>
                </BlueOverlay>
            </div>
        </div>
    );
};

export default SolutionsTemplate;
