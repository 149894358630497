import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sitekey } from "../../config/config.js";

const InvisCaptcha = props => {
    return (
        <ReCAPTCHA
            ref={props.recaptchaRef}
            size="invisible"
            onChange={props.action}
            sitekey={sitekey}
        />
    );
};

export default InvisCaptcha;
