import {
    faHandHoldingHeart,
    faShieldAlt,
    faChartLine,
} from "@fortawesome/free-solid-svg-icons";

import CaseStudiesData from "./CaseStudiesData";

const HomeData = {
    header1: {
        headerTitle: "The smart way to manage your patients.",
        headerDescription:
            "Using data to help bridge the physican and patient care gap.",
        buttonText: "GET STARTED FOR FREE",
        extraInfoSpan: "Already using JustPractice?",
        extraInfoA: "Sign in",
    },
    panorama: {
        headerText:
            "The most accurate and high quality EMR data analysis to help physicians manage their patients.",
    },
    advantages: [
        {
            src: faHandHoldingHeart,
            title: "Providing smart value",
            content:
                "No one knows your patients more than you do. Our software aims to make tailored recommendations based on the way you practice to improve efficiency and quality. Our software won’t burden you with recommendations that are not applicable or actionable, thus minimizing burnout.",
        },
        {
            src: faShieldAlt,
            title: "Highly secure",
            content: `Data can be intimidating. Whether the
                stakeholder is a physician, clinical
                manager, or a key decision maker in an
                organization, no one has time to interpret
                messy, complicated data. Panorama provides
                completely processed data in the most
                intuitive way possible to distill the
                information down to only what one needs to
                know to cut out the noise.`,
        },
        {
            src: faChartLine,
            title: "Improves outcomes",
            content:
                "Analytical software for automating the tedious process of identifying patient opportunities for both billing and quality improvement.",
        },
    ],
    supporters: {
        headerText: "Trusted All Over The World",
        supportersList: [
            {
                logoSrc: "divisions_pic.png",
                name: "Divisions of Family Practice",
            },
            {
                logoSrc: "edocs.png",
                name: "Electronic Medical Record",
            },
            {
                logoSrc: "logo-camrosepcn.png",
                name: "Camrose Primary Care Network",
            },
            {
                logoSrc: "logo-gpsc.png",
                name: "General Practice Services Committee",
            },
        ],
    },
    header2: {
        headerText:
            "Join a nationwide network of physicians already using JustPractice to manage their patients.",
        buttonText: "GET STARTED FOR FREE",
    },
    testimonials: {
        headerText:
            "Driving better medical practices for the most caring physicians.",
        sliderArr: [
            {
                name: "Dr. Eric Cadesky",
                text:
                    "The JustPractice team made the GPSC Paneling process seamless and enjoyable. I learned a lot from the exercise and cannot recommend JustPractice highly enough.",
                background: "testimonial_1.jpg",
                avatar: "doctorsoffice.jpg",
                location: "DOCTOR'S OFFICE",
            },
            {
                name: "Broadway Plaza Family Practice",
                text:
                    "JustPractice was very helpful and accommodating. They are responsive to questions and open to building customizations to help things move along more smoothly. Fantastic company to help optimize EMR usage.",
                background: "testimonial_4.jpg",
                avatar: "broadway-plaza-family-practice-logo.jpg",
                location: "",
            },
            {
                name: "Dr. Dan Ezekiel",
                background: "testimonial_2.jpg",
                avatar: "danezekiel.jpg",
                text: "I was so impressed with the team at JustPractice.",
            },
            {
                name: "Dr. Angela Lee",
                text:
                    "Your system is extremely efficient and precise. You help us with producing accurate data. Your help has expedited the entire process.",
                background: "testimonial_3.jpg",
                avatar: "crossroads-medical-logo.jpg",
                location: "CROSSROADS MEDICAL",
            },
            {
                name: "Dr. Duncan Miller",
                text:
                    "I was very pleased to work with JustPractice and appreciated their help in cleaning up my panel. Highly recommend their services.",
                background: "testimonial_5.jpg",
                avatar: "broadway-plaza-family-practice-logo.jpg",
                location: "BROADWAY PLAZA FAMILY PRACTICE",
            },
        ],
    },
    stories: {
        headerText: "More Stories",
        storiesContent: [
            CaseStudiesData[1],
            CaseStudiesData[0],
            CaseStudiesData[7],
        ],
    },
};

export default HomeData;
