import React from "react";
import { Switch, Route } from "react-router-dom";
import { Navbar, Footer } from "./components";
import "./styles/App.scss";
import {
    Home,
    CaseStudy,
    CaseStudies,
    Contact,
    UserConfidentiality,
    Error,
    PhysicianOrganizations,
    MedicalAssociations,
    PatientMedicalHomeTeams,
    GuidelineSocieties,
    PharmaceuticalCorporations,
    PrivacyPolicy,
} from "./pages";

const App = () => {
    return (
        <div className="App">
            <Navbar />
            <div
                className="App-content-wrapper"
                style={{ paddingTop: "4.563em" }}
            >
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/case_studies/:id" component={CaseStudy} />
                    <Route path="/resources" component={CaseStudies} />
                    <Route path="/contact_us" component={Contact} />
                    <Route path="/privacy_policy" component={PrivacyPolicy} />
                    <Route
                        path="/physician_organizations"
                        component={PhysicianOrganizations}
                    />
                    <Route
                        path="/guideline_societies"
                        component={GuidelineSocieties}
                    />
                    <Route
                        path="/medical_associations"
                        component={MedicalAssociations}
                    />
                    <Route
                        path="/patient_medical_home_teams"
                        component={PatientMedicalHomeTeams}
                    />
                    <Route
                        path="/pharmaceutical_corporations"
                        component={PharmaceuticalCorporations}
                    />
                    <Route
                        path="/user_confidentiality"
                        component={UserConfidentiality}
                    />
                    <Route path="*" component={Error} />
                </Switch>
            </div>
            <Footer />
        </div>
    );
};

export default App;
